import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import dayjs from "dayjs";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useSelector } from "react-redux";

import ROLES from "constants/roles";
import STATUS from "constants/status";
import DATE_TIME from "constants/date-time";

import AppIcon from "components/app-icon";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppInputDate from "components/app-input-date";

import closeIcon from "assets/images/close-icon.png";
import chevronIcon from "assets/images/chevron-right-icon.png";

const AppPendingCorrectiveAttendanceViewModal = (props, ref) => {
	const [visible, setVisible] = useState(false);
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.ATTENDANCE_MANAGEMENT], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);

	const formik = useFormik({
		initialValues: {
			name: "",
			id: "",
			employeeId: "",
			status: "",
			date: "",
			submitDate: "",
			previousClockIn: "",
			correctedClockIn: "",
			previousClockOut: "",
			correctedClockOut: "",
			remarks: ""
		},
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	//prettier-ignore
	const onHandleShow = useCallback((obj) => {
		formik.setValues({
			id: obj?.id || "",
			employeeId: obj?.attendanceRecordSignature.submittedBy?.employeeId,
			name: obj?.attendanceRecordSignature.submittedBy?.personalInfo?.fullName,
			status: obj?.status || STATUS.APPROVED,
			reviewedBy: obj?.reviewedBy || "",
			date: dayjs(obj?.attendanceRecordSignature?.correctiveDate) || "",
			submitDate: dayjs(obj?.attendanceRecordSignature?.submittedDate) || "",
			previousClockIn: dayjs(obj?.clockIn).format(DATE_TIME.H_MM_A) || "",
			correctedClockIn: dayjs(obj?.clockInCorrective).format(DATE_TIME.H_MM_A) || "",
			previousClockOut: dayjs(obj?.clockOut).format(DATE_TIME.H_MM_A) || "",
			correctedClockOut: dayjs(obj?.clockOutCorrective).format(DATE_TIME.H_MM_A) || "",
			reportingManagerId: obj?.attendanceRecordSignature?.submittedBy?.reportingManagerId || "",
			supervisorId: obj?.attendanceRecordSignature?.submittedBy?.supervisorId || "",
			remarks: obj?.remark || "N/A",
		});

		setVisible(true);
	}, [formik]);

	const actionPermission = useMemo(() => (props.employeeId === formik.values.supervisorId && formik.values.status === STATUS.PENDING) || (props.employeeId === formik.values.reportingManagerId && formik.values.status === STATUS.APPROVED_BY_SUPERVISOR), [formik.values, props.employeeId]);

	//prettier-ignore
	const onHandleShowBack = useCallback((obj) => {
		formik.setValues(obj);

		setVisible(true);
	}, [formik]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);

		formik.resetForm();
	}, [formik]);

	//prettier-ignore
	const onHandleSubmit = useCallback((values) => {
		props.onHandleSubmit([values], STATUS.APPROVED);

		onHandleDismiss();
	}, [onHandleDismiss, props]);

	const onHandleReject = useCallback(() => {
		props.onHandleRejectCorrectives([formik.values], false);

		onHandleDismiss();
	}, [formik.values, onHandleDismiss, props]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss,
		onHandleShowBack: onHandleShowBack
	}));

	return (
		<Modal classes={{ root: "app-pending-corrective-attendance-view-modal" }} open={visible}>
			<div className="pending-corrective-attendance-view-modal">
				<button type="button" className="pending-corrective-attendance-view-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<h1 className="pending-corrective-attendance-view-modal__title">Corrective Attendance Details</h1>

				<form className="pending-corrective-attendance-view-modal__form" onSubmit={formik.handleSubmit}>
					<div className="pending-corrective-attendance-view-modal__row">
						<AppInput disabled type="text" name="name" label="Name" value={formik.values.name} error={formik.errors.name} touched={formik.touched.name} onChange={formik.handleChange} />

						<AppInput disabled type="text" name="employeeId" label="Employee ID" value={formik.values.employeeId} error={formik.errors.employeeId} touched={formik.touched.employeeId} onChange={formik.handleChange} />
					</div>

					<div className="pending-corrective-attendance-view-modal__row">
						<AppInput disabled type="text" name="status" label="Status" value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />
					</div>

					<div className="pending-corrective-attendance-view-modal__row">
						<AppInputDate disabled type="text" name="date" label="Date" value={formik.values.date} error={formik.errors.date} touched={formik.touched.date} onChange={formik.handleChange} />

						<AppInputDate disabled type="text" name="submitDate" label="Submit Date" value={formik.values.submitDate} error={formik.errors.submitDate} touched={formik.touched.submitDate} onChange={formik.handleChange} />
					</div>

					<div className="pending-corrective-attendance-view-modal__row">
						<AppInput disabled type="text" name="previousClockIn" label="Previous Clock In" value={formik.values.previousClockIn} error={formik.errors.previousClockIn} touched={formik.touched.previousClockIn} onChange={formik.handleChange} />

						<AppIcon className="pending-corrective-attendance-view-modal__chevron" src={chevronIcon} />

						<AppInput disabled type="text" name="correctedClockIn" label="Corrected Clock In" value={formik.values.correctedClockIn} error={formik.errors.correctedClockIn} touched={formik.touched.correctedClockIn} onChange={formik.handleChange} />
					</div>

					<div className="pending-corrective-attendance-view-modal__row">
						<AppInput disabled type="text" name="previousClockOut" label="Previous Clock Out" value={formik.values.previousClockOut} error={formik.errors.previousClockOut} touched={formik.touched.previousClockOut} onChange={formik.handleChange} />

						<AppIcon className="pending-corrective-attendance-view-modal__chevron" src={chevronIcon} />

						<AppInput disabled type="text" name="correctedClockOut" label="Corrected Clock Out" value={formik.values.correctedClockOut} error={formik.errors.correctedClockOut} touched={formik.touched.correctedClockOut} onChange={formik.handleChange} />
					</div>

					<AppInput disabled type="textarea" name="remarks" label="Remarks" multiline maxLength={255} value={formik.values.remarks} touched={formik.touched.remarks} error={formik.errors.remarks} onChange={formik.handleChange} />

					<div className="pending-corrective-attendance-view-modal__button-container">
						{(actionPermission || props.isHR) && (
							<div className="pending-corrective-attendance-view-modal__reject-button">
								<AppButton disabled={restricted} outline type="button" label="Reject" onClick={onHandleReject} />
							</div>
						)}

						{actionPermission && (
							<div className="pending-corrective-attendance-view-modal__approve-button">
								<AppButton disabled={restricted} type="submit" label="Approve" />
							</div>
						)}
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppPendingCorrectiveAttendanceViewModal));

AppPendingCorrectiveAttendanceViewModal.propTypes = {
	onHandleRejectCorrectives: PropTypes.func
};
