import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";

import api from "services/api";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import STATUS from "constants/status";
import DATE_TIME from "constants/date-time";
import ENDPOINT_PATH from "constants/end-point-path";

import AppIcon from "components/app-icon";
import AppButton from "components/app-button";
import AppStatus from "components/app-status";
import AppCheckbox from "components/app-checkbox";
import AppTable from "components/app-table/app-table";
import AppPendingCorrectiveAttendanceViewEmployeeModal from "components/pages/human-resources/app-pending-corrective-attendance-view-employee-modal";
import AppPendingCorrectiveAttendanceRejectionEmployeeModal from "components/pages/human-resources/app-pending-corrective-attendance-rejection-employee-modal";

import eyeIcon from "assets/images/eye-open-icon.png";
import chevronIcon from "assets/images/chevron-right-icon.png";
import rejectIcon from "assets/images/pages/customer/reject-icon.svg";
import approveIcon from "assets/images/pages/customer/approve-icon.svg";

const AppPendingCorrectiveAttendanceEmployeeTable = (props) => {
	const { userId } = useParams();
	const dispatch = useDispatch();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const [searchParams, setSearchParams] = useSearchParams();
	const [data, setData] = useState(convertPaginationTableData());
	const [selectedPending, setSelectedPending] = useState([]);
	const [selectedPermission, setSelectedPermission] = useState([]);
	const employeeId = useMemo(() => props.employeeId, [props.employeeId]);
	const isHR = useMemo(() => props.isHR, [props.isHR]);
	const rejectionRef = useRef();
	const pendingAttendanceRef = useRef();
	const memoSearchParams = useRef(setSearchParams);
	const queryParams = useRef({ page: 0, keyword: searchParams.get("keyword") || "", tab: "PENDING_CORRECTIVE_ATTENDANCE" });

	//prettier-ignore
	const onHandleGetList = useCallback(async () => {
		let response = null;

		try {
			const params = sanitizeObject({ ...queryParams.current, size: 10, "user-id": userId });

			memoSearchParams.current(params);

			response = await api.get.humanResource.pendingCorrectiveAttendance(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);

			setSelectedPending([]);

			setSelectedPermission([]);
		}
	}, [userId]);

	//prettier-ignore
	const onHandleSelectCorrectiveAttendance = useCallback((boolean, selectedData, permissionId) => {
		setSelectedPending((prev) => {
			let nextPending = [...prev];

			if (boolean) {
				nextPending.push(selectedData);
			} else {
				nextPending = nextPending.filter((id) => id !== selectedData);
			}

			if (!isHR) {
				setSelectedPermission((prevStatus) => {
					if (!nextPending?.length) {
						return [];
					} else if (boolean) {
						return permissionId;
					}

					return prevStatus;
				});
			}

			return nextPending;
		});
	}, [isHR]);

	//prettier-ignore
	const onHandleSelectAll = useCallback((boolean) => {
		if (boolean) {
			const ids = data.content.filter(item => !selectedPermission.length || (item.attendanceRecordSignature.submittedBy.supervisorId === selectedPermission && item.status === STATUS.PENDING) || (item.attendanceRecordSignature.submittedBy.reportingManagerId === selectedPermission && item.status === STATUS.APPROVED_BY_SUPERVISOR)).map(item => item);
			
			setSelectedPending(ids);
		} else {
			setSelectedPending([]);

			setSelectedPermission([]);
		}
	}, [data.content, selectedPermission]);

	//prettier-ignore
	const onHandleViewCorrectiveAttendance = useCallback((obj) => {
		pendingAttendanceRef.current.onHandleShow(data.content[obj.rowIndex]);
	}, [data]);

	const onHandleRejectCorrectives = useCallback((selectedData, isCancel) => {
		rejectionRef.current.onHandleShow(selectedData, isCancel);
	}, []);

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values, status) => {
		let response = null;

		const payload = values.map((item) => {
			return { id: item.id, remark: item?.rejectReason, status: status };
		});
		
		try {
			await api.post.humanResource.updateCorrectiveAttendanceStatus(payload);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			if (status === STATUS.APPROVED) dispatch(promptLayoutAlertMessage({ message: "Pending Corrective Attendance was approved successfully" }));
			else if (status === STATUS.REJECTED) dispatch(promptLayoutAlertMessage({ message: "Pending Corrective Attendance was rejected successfully" }));

			onHandleGetList();

			setSelectedPending([]);
		}
	}, [dispatch, onHandleGetList]);

	//prettier-ignore
	const onHandleBack = useCallback((selectedData) => {
		pendingAttendanceRef.current.onHandleShowBack(selectedData[0]);
	}, []);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "checkbox",
			options: {
				sort: false,
				customHeadRender: () => {
					const selectedAll = data.content.length > 0 && selectedPending.length === data.content.filter((item) => !selectedPermission.length || selectedPermission.includes(item.status === STATUS.PENDING ? item.attendanceRecordSignature.submittedBy.supervisorId : item.attendanceRecordSignature.submittedBy.reportingManagerId)).length;

					return (
						<TableCell key="table-key" className="app-table__cell app-table__cell--header">
							{(isHR || selectedPending.length > 0) && <AppCheckbox onClick={onHandleSelectAll} value={selectedAll} />}
						</TableCell>
					);
				},
				customBodyRender: (value, tableMeta) => {
					const rowIndex = tableMeta.rowIndex;
					const tablePending = data.content[rowIndex];
					const tableAttendanceStatus = data.content[rowIndex]?.status;
					const selected = selectedPending.some((item) => item.id === tablePending.id);
					const isSupervisor = data.content[rowIndex]?.attendanceRecordSignature.submittedBy.supervisorId === employeeId;
					const isReportingManager = data.content[rowIndex]?.attendanceRecordSignature.submittedBy.reportingManagerId === employeeId;
					const permittedId = tableAttendanceStatus === STATUS.PENDING ? data.content[rowIndex]?.attendanceRecordSignature.submittedBy.supervisorId : data.content[rowIndex]?.attendanceRecordSignature.submittedBy.reportingManagerId;
					const checkPermission = !selected.length || selected.includes(employeeId);
					const permission = (isSupervisor && tableAttendanceStatus === STATUS.PENDING) || (isReportingManager && tableAttendanceStatus === STATUS.APPROVED_BY_SUPERVISOR) || isHR;

					return (
						<Table>
							<TableBody>
								<TableRow>
									<TableCell className="app-table__cell">{permission && <AppCheckbox className="checkbox" disabled={!checkPermission} onClick={(v) => onHandleSelectCorrectiveAttendance(v, tablePending, permittedId)} value={selected} />} </TableCell>
								</TableRow>
							</TableBody>
						</Table>
					);
				}
			}
		},
		{
			name: "attendanceRecordSignature.correctiveDate",
			label: "Date",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.DD_MM_YYYY)
			}
		},
		{
			name: "clockIn",
			label: "Previous Clock In",
			options: {
				sort: false,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.H_MM_A)
			}
		},
		{
			name: "symbol",
			options: {
				sort: false,
				customHeadRender: () => {
					return (
						<TableCell key="table-key-clock-in" className="app-table__cell app-table__cell--header">
							<AppIcon src={chevronIcon} />
						</TableCell>
					);
				},
				customBodyRender: () => <AppIcon src={chevronIcon} />
			}
		},
		{
			name: "clockInCorrective",
			label: "Corrected Clock In",
			options: {
				sort: false,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.H_MM_A)
			}
		},
		{
			name: "clockOut",
			label: "Previous Clock Out",
			options: {
				sort: false,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.H_MM_A)
			}
		},
		{
			name: "symbol",
			options: {
				sort: false,
				customHeadRender: () => {
					return (
						<TableCell key="table-key-clock-out" className="app-table__cell app-table__cell--header">
							<AppIcon src={chevronIcon} />
						</TableCell>
					);
				},
				customBodyRender: () => <AppIcon src={chevronIcon} />
			}
		},
		{
			name: "clockOutCorrective",
			label: "Corrected Clock Out",
			options: {
				sort: false,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.H_MM_A)
			}
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: false,
				customBodyRender: (value) => <AppStatus status={value} />
			}
		},
		{
			name: "view",
			label: "View",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__view" onClick={() => onHandleViewCorrectiveAttendance(tableMeta)}>
							<img src={eyeIcon} alt="view-icon" />
						</button>
					);
				}
			}
		}
	], [data.content, selectedPending, isHR, onHandleSelectAll, selectedPermission, employeeId, onHandleSelectCorrectiveAttendance, onHandleViewCorrectiveAttendance]);

	//prettier-ignore
	const HeaderApprovalButton = useCallback((obj) => {
		if (!obj.selectedPending.length) return null;

		return (
			<div className="pending-corrective-attendance-employee-table__header-button">
				<div className="pending-corrective-attendance-employee-table__reject-button">
					<AppButton outline type="button" label="Reject" icon={rejectIcon} onClick={() => {obj.onHandleRejectCorrectives(obj.selectedPending, true)}} />
				</div>

				{!obj.isHR && <div className="pending-corrective-attendance-employee-table__approve-button">
					<AppButton type="button" label="Approve" icon={approveIcon} onClick={() => {obj.onHandleSubmit(obj.selectedPending, STATUS.APPROVED)}} />
				</div>}
			</div>
		);
	}, []);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		enableNestedDataAccess: ".",
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList();
					break;
				default:
					break;
			}
		}
	}), [data, onHandleGetList]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.HUMAN_RESOURCE.PENDING_CORRECTIVE_ATTENDANCE);
		};
	}, [cancelRequest]);

	return (
		<div className="app-pending-corrective-attendance-employee-table">
			<div className="pending-corrective-attendance-employee-table">
				<div className="pending-corrective-attendance-employee-table__header">
					<HeaderApprovalButton selectedPending={selectedPending} onHandleSubmit={onHandleSubmit} onHandleRejectCorrectives={onHandleRejectCorrectives} isHR={isHR} />
				</div>

				<AppTable data={data.content} columns={tableColumns} options={tableOptions} />
			</div>

			<AppPendingCorrectiveAttendanceViewEmployeeModal ref={pendingAttendanceRef} onHandleRejectCorrectives={onHandleRejectCorrectives} onHandleSubmit={onHandleSubmit} isHR={isHR} employeeId={employeeId} />

			<AppPendingCorrectiveAttendanceRejectionEmployeeModal ref={rejectionRef} onHandleBack={onHandleBack} onHandleSubmit={onHandleSubmit} />
		</div>
	);
};

export default AppPendingCorrectiveAttendanceEmployeeTable;
