import React, { useCallback, useContext, useEffect, useMemo, useRef } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import pathnames from "routes/pathnames";

import api from "services/api";
import getSparePartListing from "services/get-spare-part-listing";
import getServiceStatusListing from "services/get-service-status-listing";
import getInventoryLocationListing from "services/get-inventory-location-listing";
import getSparePartCategoryListing from "services/get-spare-part-category-listing";
import getSparePartSubCategoryListing from "services/get-spare-part-sub-category-listing";

import useBreadcrumb from "hooks/use-breadcrumb";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import formatCurrencyPattern from "common/format-currency-pattern";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import PAGE from "constants/page";
import ROLES from "constants/roles";
import ERRORS from "constants/errors";
import STATUS from "constants/status";
import ENDPOINT_PATH from "constants/end-point-path";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";
import AppInventoryTransferModal from "components/pages/inventory/app-inventory-transfer-modal";
import AppInventoryMinusStockModal from "components/pages/inventory/app-inventory-minus-stock-modal";
import AppInventoryAdjustStockModal from "components/pages/inventory/app-inventory-adjust-stock-modal";

import minusIcon from "assets/images/minus-icon.png";
import addIcon from "assets/images/add-blue-icon.png";
import transferIcon from "assets/images/pages/inventory/transfer-icon.png";

const PageInventoryCreateEdit = () => {
	const { id } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.ROLE], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const title = useMemo(() => (isCreate ? "Add Item" : "Edit Item"), [isCreate]);
	const submitLabel = useMemo(() => (isCreate ? "Add" : "Update"), [isCreate]);
	const inventoryTransferModalRef = useRef();
	const inventoryMinusStockModalRef = useRef();
	const inventoryAdjustStockModalRef = useRef();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const sparePartKeyword = useRef();
	const locationKeyword = useRef();

	const breadCrumb = useMemo(() => {
		const data = [
			{ label: "Inventory", path: pathnames.inventory.inventories },
			{ label: "Inventory Listing", path: pathnames.inventory.inventories }
		];

		if (isCreate) {
			data.push({ label: "Add Item", path: pathnames.inventory.inventoryCreateEdit + PAGE.CREATE });
		}

		if (!isCreate) {
			data.push({ label: "Edit Item " + id, path: pathnames.inventory.inventoryCreateEdit + id });
		}

		return data;
	}, [isCreate, id]);

	useBreadcrumb({ breadCrumb });

	const initialValues = useMemo(() => {
		const values = {
			isCreate: isCreate,
			barcodeId: "",
			sparePartImage: "",
			status: STATUS.IN_USE,
			sparePartId: "",
			sparePartCategory: "",
			sparePartSubCategory: "",
			quantity: "",
			reservedQuantity: "",
			minimumThreshold: "",
			locationId: "",
			costPrice: "",
			retailPrice: "",
			supplierName: "",
			quantityToDeduct: "",
			remark: ""
		};

		return values;
	}, [isCreate]);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			status: yup.string().required(ERRORS.REQUIRED),
			sparePartId: yup.string().required(ERRORS.REQUIRED),
			quantity: yup.number().required(ERRORS.REQUIRED),
			minimumThreshold: yup.number().required(ERRORS.REQUIRED),
			locationId: yup.string().required(ERRORS.REQUIRED),
			costPrice: yup.string().when(["isCreate"], {
				is: (boolean) => boolean,
				then: () => yup.string().required(ERRORS.REQUIRED)
			}),
			retailPrice: yup.string().when(["isCreate"], {
				is: (boolean) => boolean,
				then: () => yup.string().required(ERRORS.REQUIRED)
			}),
			supplierName: yup.string().when(["isCreate"], {
				is: (boolean) => boolean,
				then: () => yup.string().required(ERRORS.REQUIRED)
			})
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);

	// prettier-ignore
	const onHandleGetDetails = useCallback(async (uniqueId) => {
		let response = null;

		try {
			response = await api.get.inventory.inventory({ "inventory-id": uniqueId });
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			memoSetFormValues({
				id: response.id,
				inventoryLocationId: response.inventoryLocation.id,
				barcodeId: response.referenceNo,
				sparePartImage: response.sparePartImage,
				status: response.status,
				sparePartId: response.sparePart.id,
				sparePartCategory: response.sparePart.sparePartCategoryId,
				sparePartSubCategory: response.sparePart.sparePartSubCategoryId,
				quantity: response.quantity,
				reservedQuantity: response.reservedQuantity,
				minimumThreshold: response.minimumThreshold,
				locationId: response.inventoryLocation.id,
				costPrice: response.costPrice,
				nextCostPrice: response.costPrice,
				retailPrice: response.retailPrice,
				nextRetailPrice: response.retailPrice,
				supplierName: response.supplierName,
				nextSupplier: response.supplierName,
				remark: response.remark
			});

			sparePartKeyword.current = response?.sparePart?.name;
			locationKeyword.current = response?.inventoryLocation.name
		}
	}, [memoSetFormValues]);

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			let payload = {
				status: values.status,
				minimumThreshold: values.minimumThreshold,
				locationId: values.locationId
			};

			if (isCreate) {
				payload = { ...payload, sparePartId: values.sparePartId, quantity: values.quantity, costPrice: values.costPrice, retailPrice: values.retailPrice, supplierName: values.supplierName };

				await api.post.inventory.createInventory(payload);
			}

			if (!isCreate) {
				payload.id = id;

				await api.post.inventory.updateInventory(payload);
			}

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			formik.resetForm();

			if (isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Inventory was added successfully!" }));
			}

			if (!isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Inventory was updated successfully!" }));
			}

			navigate(pathnames.inventory.inventories);
		}
	}, [isCreate, id, formik, navigate, dispatch]);

	//prettier-ignore
	const onHandleSparePartList = useCallback(async (event) => {
		const sparePartList = await getSparePartListing(event);
		const sparePartImage = sparePartList.content.filter((o) => o?.value === formik.values.sparePartId);

		formik.setFieldValue("sparePartImage", sparePartImage[0]?.doc?.fileUrl);
		
		return sparePartList;
	}, [formik]);

	//prettier-ignore
	const onHandleSelectSparePart = useCallback(async (event) => {
		const value = event.target.value;

		try {
			formik.handleChange(event);

			const sparePartListing = await api.get.sparePart.sparePart(value);
			
			let sparePartCategoryName = "";
			let sparePartSubCategoryName = "";

			if (sparePartListing.sparePartCategoryId) {
				const sparePartCategoryId = await api.get.sparePartCategory.sparePartCategory(sparePartListing.sparePartCategoryId);

				sparePartCategoryName = sparePartCategoryId.id;
			}

			if (sparePartListing.sparePartSubCategoryId) {
				const sparePartSubCategoryId = await api.get.sparePartSubCategory.sparePartSubCategory(sparePartListing.sparePartSubCategoryId);

				sparePartSubCategoryName = sparePartSubCategoryId.id;
			}
			
			formik.setFieldValue("sparePartImage", sparePartListing?.doc?.fileUrl);
			formik.setFieldValue("sparePartCategory", sparePartCategoryName);
			formik.setFieldValue("sparePartSubCategory", sparePartSubCategoryName);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}
	}, [formik]);

	const onHandleInventoryTransfer = () => {
		inventoryTransferModalRef.current.onHandleShow(formik.values);
	};

	const onHandleShowAdjustStock = useCallback(() => {
		inventoryAdjustStockModalRef.current.onHandleShow(formik.values);
	}, [formik]);

	const onHandleShowMinusStock = useCallback(() => {
		inventoryMinusStockModalRef.current.onHandleShow(formik.values);
	}, [formik]);

	const onHandleBack = useCallback(() => {
		navigate(pathnames.inventory.inventories);
	}, [navigate]);

	useEffect(() => {
		if (!isCreate) {
			onHandleGetDetails(id);
		}
	}, [isCreate, id, onHandleGetDetails]);

	useEffect(() => {
		if (!isCreate) {
			return () => {
				cancelRequest(ENDPOINT_PATH.INVENTORY.INVENTORY);
			};
		}
	}, [cancelRequest, isCreate]);

	return (
		<div className="page-inventory-create-edit">
			<div className="inventory-create-edit">
				<div className="inventory-create-edit__header">
					<h1 className="inventory-create-edit__title">{title}</h1>

					{!isCreate && (
						<div className="inventory-create-edit__header inventory-create-edit__header--column">
							<div className="inventory-create-edit__transfer-button">
								<AppButton disabled={restricted} type="button" label="Transfer" icon={transferIcon} onClick={onHandleInventoryTransfer} />
							</div>

							<p className="inventory-create-edit__last-update">
								<span>Last Updated By</span> Ahmad Muhammad Ali, 03/11/2023, 10:00 am
							</p>
						</div>
					)}
				</div>

				<form className="inventory-create-edit__form" onSubmit={formik.handleSubmit}>
					<div className="inventory-create-edit__container">
						<p className="inventory-create-edit__label">General Details</p>

						<div className="inventory-create-edit__row">
							<div className="inventory-create-edit__image">{formik.values.sparePartImage && <img name="sparePartImage" src={formik.values.sparePartImage} alt="spare-part" />}</div>
						</div>

						<div className="inventory-create-edit__row">
							{!isCreate && <AppInput disabled type="text" name="barcodeId" label="Barcode ID" placeholder="Barcode ID" value={formik.values.barcodeId} error={formik.errors.barcodeId} touched={formik.touched.barcodeId} onChange={formik.handleChange} />}

							<AppSelectInput required disabled={restricted} name="status" label="Status" placeholder="Select..." value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} loadOptions={getServiceStatusListing} onChange={formik.handleChange} />
						</div>

						<div className="inventory-create-edit__row">
							<AppSelectInput required pagination name="sparePartId" label="Spare Part" placeholder="Search using Barcode ID Name or Spare Part Name" defaultSearchValue={sparePartKeyword.current} loadOptions={onHandleSparePartList} value={formik.values.sparePartId} error={formik.errors.sparePartId} touched={formik.touched.sparePartId} onChange={onHandleSelectSparePart} />

							<AppSelectInput disabled type="text" name="sparePartCategory" label="Spare Part Category" placeholder="" loadOptions={getSparePartCategoryListing} value={formik.values.sparePartCategory} error={formik.errors.sparePartCategory} touched={formik.touched.sparePartCategory} onChange={formik.handleChange} />
						</div>

						<div className="inventory-create-edit__row">
							<AppSelectInput disabled type="text" name="sparePartSubCategory" label="Spare Part Sub-Category" placeholder="" loadOptions={getSparePartSubCategoryListing} value={formik.values.sparePartSubCategory} error={formik.errors.sparePartSubCategory} touched={formik.touched.sparePartSubCategory} onChange={formik.handleChange} />
						</div>
					</div>

					<div className="inventory-create-edit__container inventory-create-edit__container--divider">
						<p className="inventory-create-edit__label">Stock Count</p>

						<div className="inventory-create-edit__row">
							<div className="inventory-create-edit__column">
								<AppInput required disabled={!isCreate} type="number" name="quantity" label="Quantity" placeholder="Enter Quantity" value={formik.values.quantity} error={formik.errors.quantity} touched={formik.touched.quantity} onChange={formik.handleChange} />

								{!isCreate && <AppInput required disabled type="number" name="reservedQuantity" label="Reserved Quantity" value={formik.values.reservedQuantity} error={formik.errors.reservedQuantity} touched={formik.touched.reservedQuantity} onChange={formik.handleChange} />}
							</div>

							<div className="inventory-create-edit__column">
								{!isCreate && (
									<div className="inventory-create-edit__threshold-buttons">
										<AppButton disabled={restricted} type="button" label="Adjust Stock" icon={addIcon} onClick={onHandleShowAdjustStock} />

										<AppButton disabled={restricted} type="button" label="Minus Stock" icon={minusIcon} onClick={onHandleShowMinusStock} />
									</div>
								)}

								<AppInput required disabled={restricted} type="number" name="minimumThreshold" label="Minimum Threshold" placeholder="Enter Minimum Threshold" value={formik.values.minimumThreshold} error={formik.errors.minimumThreshold} touched={formik.touched.minimumThreshold} onChange={formik.handleChange} />
							</div>
						</div>

						<div className="inventory-create-edit__row">
							<AppSelectInput pagination required disabled={restricted} name="locationId" label="Location" placeholder="Select..." defaultSearchValue={locationKeyword.current} loadOptions={(payload) => getInventoryLocationListing({ editId: !isCreate ? id : null, keyword: payload?.keyword })} value={formik.values.locationId} error={formik.errors.locationId} touched={formik.touched.locationId} onChange={formik.handleChange} />
						</div>
					</div>

					<div className="inventory-create-edit__container">
						<p className="inventory-create-edit__label">Supplier Section</p>

						<div className="inventory-create-edit__row">
							<AppInput disabled={!isCreate || restricted} required type="text" name="supplierName" label="Supplier" placeholder="Enter Supplier Name" value={formik.values.supplierName} error={formik.errors.supplierName} touched={formik.touched.supplierName} onChange={formik.handleChange} />

							<div className="inventory-create-edit__column">
								<AppInput disabled={!isCreate || restricted} required type="text" name="costPrice" label="Cost Price (MYR)" placeholder="Enter Cost Price" value={formik.values.costPrice} error={formik.errors.costPrice} touched={formik.touched.costPrice} onChange={formik.handleChange} onFormat={formatCurrencyPattern} />

								<AppInput disabled={!isCreate || restricted} required type="text" name="retailPrice" label="Sell Price (MYR)" placeholder="Enter Sell Price" value={formik.values.retailPrice} error={formik.errors.retailPrice} touched={formik.touched.retailPrice} onChange={formik.handleChange} onFormat={formatCurrencyPattern} />
							</div>
						</div>
					</div>

					<div className="inventory-create-edit__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleBack} />

						<AppButton type="submit" label={submitLabel} disabled={formik.isSubmitting || restricted} />
					</div>
				</form>
			</div>

			<AppInventoryTransferModal ref={inventoryTransferModalRef} onHandleGetDetails={onHandleGetDetails} />

			<AppInventoryAdjustStockModal ref={inventoryAdjustStockModalRef} onHandleGetDetails={onHandleGetDetails} />

			<AppInventoryMinusStockModal ref={inventoryMinusStockModalRef} onHandleGetDetails={onHandleGetDetails} />
		</div>
	);
};

export default PageInventoryCreateEdit;
