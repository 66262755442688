import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import PropTypes from "prop-types";
import { Modal } from "@mui/material";

import STATUS from "constants/status";

import AppInventoryHistoryIssueComponent from "components/pages/inventory/app-inventory-history-issue-component";
import AppInventoryHistoryApproveRejectComponent from "components/pages/inventory/app-inventory-history-approve-reject-component";

const AppInventoryHistoryConfirmationModal = (props, ref) => {
	const [visible, setVisible] = useState(false);
	const [data, setData] = useState(null);
	const [status, setStatus] = useState(null);
	const isApprove = useMemo(() => status === STATUS.APPROVED, [status]);
	const isIssue = useMemo(() => status === STATUS.ISSUED, [status]);

	const onHandleShow = useCallback((data, status) => {
		setData(data);

		setStatus(status);

		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		props.onHandleDismiss(data.id);

		setVisible(false);
	}, [data, props]);

	const onHandleConfirm = useCallback(() => {
		setVisible(false);

		if (isApprove) {
			props.onHandleApprove(data);
		} else {
			props.onHandleReject(data);
		}
	}, [isApprove, props, data]);

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		setVisible(false);

		props.onHandleIssue(data, values.signatureAttachment);
	}, [props, data]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-inventory-history-confirmation-modal" }} open={visible}>
			<div className="inventory-history-confirmation-modal">
				<h1 className="inventory-history-confirmation-modal__title">Are you sure?</h1>

				{!isIssue && <AppInventoryHistoryApproveRejectComponent status={status} data={data} onHandleConfirm={onHandleConfirm} onHandleDismiss={onHandleDismiss} />}

				{isIssue && <AppInventoryHistoryIssueComponent data={data} onHandleSubmit={onHandleSubmit} onHandleDismiss={onHandleDismiss} />}
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppInventoryHistoryConfirmationModal));

AppInventoryHistoryConfirmationModal.propTypes = {
	onHandleDismiss: PropTypes.func.isRequired
};
