import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useRef, useState } from "react";

import dayjs from "dayjs";
import { useFormik } from "formik";
import { Modal } from "@mui/material";
import { useSelector } from "react-redux";

import api from "services/api";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import ROLES from "constants/roles";
import STATUS from "constants/status";

import AppIcon from "components/app-icon";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppInputDate from "components/app-input-date";
import AppInputDragAndDropFiles from "components/app-input-drag-and-drop-files";

import closeIcon from "assets/images/close-icon.png";

const AppViewLeaveDetailsModal = (props, ref) => {
	const [visible, setVisible] = useState(false);
	const downloadingFile = useRef(false);
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.CLAIM_TYPE_CONFIG], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);

	const initialValues = useMemo(() => {
		const values = {
			id: "",
			employeeName: "",
			days: "",
			status: "",
			reviewedBy: "",
			timeOffType: "",
			endDate: "",
			startDate: "",
			leaveType: "",
			employeeId: "",
			submitDate: "",
			description: "",
			rejectReason: "",
			file: []
		};

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues
	});

	const onHandleReject = useCallback(() => {
		setVisible(false);

		props.onHandleRejectLeave(formik.values.id);
	}, [formik, props]);

	const onHandleDownloadFile = useCallback(async (fileObject) => {
		if (downloadingFile.current) return;

		downloadingFile.current = true;

		let response = null;

		try {
			const payload = { "doc-id": fileObject.id };

			response = await api.post.humanResource.downloadDoc(payload);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const url = response.fileUrl;
			const a = document.createElement("a");
			document.body.appendChild(a);

			a.href = url;
			a.download = response.fileName;
			a.click();

			setTimeout(() => {
				window.URL.revokeObjectURL(url);
				document.body.removeChild(a);
				downloadingFile.current = false;
			}, 0);
		}
	}, []);

	//prettier-ignore
	const onHandleShow = useCallback((obj) => {
		formik.setValues({
			id: obj.id,
			employeeName: obj?.employeeName || "",
			days: obj?.days || "",
			status: obj?.status || "",
			reviewedBy: obj?.reviewedBy || "",
			timeOffType: obj?.timeOffType || "",
			leaveType: obj?.leaveType || "",
			employeeId: obj?.employeeId || "",
			endDate: dayjs(obj?.endDate) || "",
			description: obj?.description || "",
			rejectReason: obj?.rejectReason || "",
			startDate: dayjs(obj?.startDate) || "",
			submitDate: dayjs(obj?.submitDate) || "",
			file: obj?.docs || ""
		});
		
		setVisible(true);
	}, [formik]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);

		formik.resetForm();
	}, [formik]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	const isRejected = useMemo(() => formik.values.status === STATUS.REJECTED, [formik]);
	const isApproved = useMemo(() => formik.values.status === STATUS.APPROVED, [formik]);

	return (
		<Modal classes={{ root: "app-view-leave-details-modal" }} open={visible}>
			<div className="view-leave-details-modal">
				<button type="button" className="view-leave-details-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<h1 className="view-leave-details-modal__title">Leave Details</h1>

				<div className="view-leave-details-modal__container">
					<div className="view-leave-details-modal__row">
						<AppInput disabled type="text" name="employeeName" label="Name" value={formik.values.employeeName} error={formik.errors.employeeName} touched={formik.touched.employeeName} onChange={formik.handleChange} />

						<AppInput disabled type="text" name="employeeId" label="Employee ID" value={formik.values.employeeId} error={formik.errors.employeeId} touched={formik.touched.employeeId} onChange={formik.handleChange} />
					</div>

					<div className="view-leave-details-modal__row">
						<AppInput disabled type="text" name="status" label="Status" value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />

						<AppInput disabled type="text" name="reviewedBy" label="Approved By" value={formik.values.reviewedBy} error={formik.errors.reviewedBy} touched={formik.touched.reviewedBy} onChange={formik.handleChange} />
					</div>

					<div className="view-leave-details-modal__row">
						<AppInput disabled type="text" name="leaveType" label="Leave Type" value={formik.values.leaveType} error={formik.errors.leaveType} touched={formik.touched.leaveType} onChange={formik.handleChange} />

						<AppInput disabled type="text" name="timeOffType" label="Time Off" value={formik.values.timeOffType} error={formik.errors.timeOffType} touched={formik.touched.timeOffType} onChange={formik.handleChange} />
					</div>

					<div className="view-leave-details-modal__row">
						<AppInputDate disabled type="text" name="startDate" label="Start Date" value={formik.values.startDate} error={formik.errors.startDate} touched={formik.touched.startDate} onChange={formik.handleChange} />

						<AppInputDate disabled type="text" name="endDate" label="End Date" value={formik.values.endDate} error={formik.errors.endDate} touched={formik.touched.endDate} onChange={formik.handleChange} />
					</div>

					<div className="view-leave-details-modal__row">
						<AppInput disabled type="text" name="days" label="Days" value={formik.values.days} error={formik.errors.days} touched={formik.touched.days} onChange={formik.handleChange} />

						<AppInputDate disabled type="text" name="submitDate" label="Submit Date" value={formik.values.submitDate} error={formik.errors.submitDate} touched={formik.touched.submitDate} onChange={formik.handleChange} />
					</div>

					<AppInput disabled type="text" multiline label="Description" name="description" value={formik.values.description} error={formik.errors.description} touched={formik.touched.description} onChange={formik.handleChange} />

					{isRejected && <AppInput disabled multiline type="textarea" label="Reject Reason" name="rejectReason" value={formik.values.rejectReason} error={formik.errors.rejectReason} touched={formik.touched.rejectReason} onChange={formik.handleChange} />}

					<AppInputDragAndDropFiles disabled name="file" value={formik.values.file} onHandleDownloadFile={onHandleDownloadFile} onChange={formik.setFieldValue} />

					{isApproved && props.isHR && (
						<div className="view-leave-details-modal__reject-button">
							<AppButton outline type="button" disabled={restricted} label="Reject" onClick={onHandleReject} />
						</div>
					)}
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppViewLeaveDetailsModal));
