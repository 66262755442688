import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useSelector } from "react-redux";

import ROLES from "constants/roles";
import ERRORS from "constants/errors";

import AppInput from "components/app-input";
import AppButton from "components/app-button";

export const AppViewEmployeePendingLeaveRejectionModal = (props, ref) => {
	const [visible, setVisible] = useState(false);
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.LEAVE_MANAGEMENT], [profile]);
	const restricted = useMemo(() => !accessible?.update, [accessible]);
	const [ids, setIds] = useState([]);
	const [cancelStatus, setCancelStatus] = useState([]);

	const initialValues = useMemo(() => {
		const values = { rejectReason: "" };

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			rejectReason: yup.string().required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	//prettier-ignore
	const onHandleShow = useCallback((id) => {
		const updateID = Array.isArray(id) ? [...id] : [id];
		const isCancel = Array.isArray(id);

		setIds(updateID);
		setVisible(true);
		setCancelStatus(isCancel);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	//prettier-ignore
	const onHandleBack = useCallback((id) => {
		props.onHandleBack(id);

		setVisible(false);

		formik.resetForm();
	}, [formik, props]);

	//prettier-ignore
	const onHandleSubmit = useCallback((values) => {
		if (ids.length > 1) {
			props.onHandleRejectReasons(values.rejectReason, ids);
		} else {
			props.onHandleRejectReason(values.rejectReason, ids);
		}

		onHandleDismiss();
	}, [ids, onHandleDismiss, props]);

	//prettier-ignore
	const FooterButton = useCallback((obj) => {
		if (obj.cancelStatus)
			return (
				<div className="view-pending-leave-rejection-modal__button-container">
					<AppButton outline type="button" label="Cancel" onClick={obj.onHandleDismiss} />

					<AppButton type="submit" label="Confirm" onClick={obj.onHandleSubmit} />
				</div>
			);

		return (
			<div className="view-pending-leave-rejection-modal__button-container">
				<AppButton outline type="button" label="Back" onClick={() => obj.onHandleBack(obj.ids)} />

				<AppButton type="submit" disabled={obj.restricted} label="Confirm" />
			</div>
		);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-view-pending-leave-rejection-modal" }} open={visible}>
			<div className="view-pending-leave-rejection-modal">
				<h1 className="view-pending-leave-rejection-modal__title">Reason for Leave Rejection</h1>

				<p className="view-pending-leave-rejection-modal__description">The reject reason will apply to all selected leave.</p>

				<AppInput multiline required type="textarea" maxLength={255} name="rejectReason" label="Reject Reason" placeholder="Enter Reject Reason" value={formik.values.rejectReason} error={formik.errors.rejectReason} touched={formik.touched.rejectReason} onChange={formik.handleChange} />

				<FooterButton cancelStatus={cancelStatus} restricted={restricted} onHandleDismiss={onHandleDismiss} onHandleSubmit={onHandleSubmit} onHandleBack={onHandleBack} ids={ids} />
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppViewEmployeePendingLeaveRejectionModal));

AppViewEmployeePendingLeaveRejectionModal.propTypes = {
	onHandleRejectReason: PropTypes.func,
	onHandleRejectReasons: PropTypes.func
};
