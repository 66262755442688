import React, { useCallback, useEffect, useMemo, useState } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import api from "services/api";

import useBreadcrumb from "hooks/use-breadcrumb";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import capitalizeCharacter from "common/capitalize-character";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import PAGE from "constants/page";
import ROLES from "constants/roles";
import ERRORS from "constants/errors";
import DATE_TIME from "constants/date-time";
import ENDPOINT_PATH from "constants/end-point-path";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppCheckbox from "components/app-checkbox";
import AppToggleSwitch from "components/app-toggle-switch";

const PageUserAccessCreateEditRole = (props) => {
	const profile = useSelector((state) => state.profile);
	const dispatch = useDispatch();
	const { id } = useParams();
	const navigate = useNavigate();
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const title = useMemo(() => (isCreate ? "Create Role" : "Edit Role"), [isCreate]);
	const submitLabel = useMemo(() => (isCreate ? "Create" : "Update"), [isCreate]);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.ROLE], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const [permissions, setPermissions] = useState([]);

	const initialValues = useMemo(() => {
		const values = {
			name: "",
			requireOtp: true,
			description: "",
			permissions: [],
			lastModifiedBy: "",
			lastModifiedDate: ""
		};

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			name: yup.string().required(ERRORS.REQUIRED),
			permissions: yup.array().required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);

	const memoCancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);

	const breadCrumb = useMemo(() => {
		const data = [
			{ label: "Operations", path: pathnames.operations.userAccess + "?tab=ROLE_MANAGEMENT" },
			{ label: "User Access", path: pathnames.operations.userAccess + "?tab=ROLE_MANAGEMENT" }
		];

		if (isCreate) {
			data.push({ label: "Create Role", path: pathnames.operations.userAccessCreateEditRole + PAGE.CREATE });
		}

		if (!isCreate) {
			data.push({ label: `Edit ${formik.values.name} Role`, path: pathnames.operations.userAccessCreateEditRole + id });
		}

		return data;
	}, [isCreate, formik.values.name, id]);

	useBreadcrumb({ breadCrumb });

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			const payload = {
				roleNameEn: values.name,
				descriptionEn: values.description,
				requireOtp: values.requireOtp,
				permissionIds: values.permissions
			};

			if (isCreate) {
				await api.post.userAccess.createRole(payload);
			}

			if (!isCreate) {
				payload.id = id;

				await api.post.userAccess.updateRole(payload);
			}

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			if (isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Role was added successfully!" }));
			}

			if (!isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Role was updated successfully!" }));
			}

			navigate(pathnames.operations.userAccess + "?tab=ROLE_MANAGEMENT");
		}
	}, [isCreate, id, formik, navigate, dispatch]);

	//prettier-ignore
	const onHandleGetDetails = useCallback(async (uniqueId) => {
		let response = null;

		try {
			response = await api.get.userAccess.role(uniqueId);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			memoSetFormValues({
				id: response.id,
				name: response.roleNameEn,
				requireOtp: response.requireOtp,
				description: response.descriptionEn,
				permissions: response.permissions.map((o) => o.id),
				lastModifiedBy: response.lastModifiedBy,
				lastModifiedDate: dayjs(response.lastModifiedDate).format(DATE_TIME.LAST_UPDATED_BY_DATE)
			});
		}
	}, [memoSetFormValues]);

	//prettier-ignore
	const onHandleSelectPermission = useCallback((obj) => {
		let values = [...formik.values.permissions];

		const permissionId = obj.id;
		const permissionName = obj.name;
		const permissionCategory = Object.keys(permissions).filter((o) => permissions[o].find((o) => o.id === obj.parentId));
		const parentPermissions = permissions[permissionCategory].find((o) => obj.parentId === o.id);
		const checkedPermission = values.findIndex((o) => o === permissionId) > -1;

		if (checkedPermission) {
			const isPermissionView = permissionName.includes(":view");

			if (isPermissionView) {
				values = values.filter((a) => parentPermissions.childs.findIndex((b) => b.id === a) === -1);
			} else {
				values = values.filter((o) => o !== permissionId);
			}
		} else {
			const actions = [":create", ":update", ":delete", ":download", ":export"];
			const isPermissionUpdate = actions.some((o) => permissionName.includes(o));

			if (isPermissionUpdate) {
				const viewPermissionId = parentPermissions.childs.find((a) => a.name.includes(":view")).id;

				const found = values.some((a) => a === viewPermissionId);

				if (!found) values.push(viewPermissionId);

				values.push(permissionId);
			} else {
				values.push(permissionId);
			}
		}

		formik.setFieldValue("permissions", values);
	}, [formik, permissions]);

	//prettier-ignore
	const onHandleSelectAllPermissions = useCallback((categoryPermissions, isSelectAll) => {
		let updatedPermissions = [...formik.values.permissions];

		categoryPermissions.forEach((permissionGroup) => {
			permissionGroup.childs.forEach((permission) => {
				const isChecked = updatedPermissions.includes(permission.id);

				if (isSelectAll && !isChecked) {
					updatedPermissions.push(permission.id);
				} else if (!isSelectAll && isChecked) {
					updatedPermissions = updatedPermissions.filter((id) => id !== permission.id);
				}
			});
		});

		formik.setFieldValue("permissions", updatedPermissions);
	}, [formik]);

	const onHandleBack = useCallback(() => {
		navigate(pathnames.operations.userAccess + "?tab=ROLE_MANAGEMENT");
	}, [navigate]);

	useEffect(() => {
		const onHandlePermissions = async () => {
			let response = null;

			try {
				response = await api.get.general.permissions();
			} catch (error) {
				serveLayoutRequestErrors(error);
			}

			if (response) {
				const nextPermissions = {};

				response.forEach((o) => {
					if (!nextPermissions[o.groupName]) {
						nextPermissions[o.groupName] = [];
					}

					nextPermissions[o.groupName].push(o);
				});

				setPermissions(nextPermissions);
			}
		};

		onHandlePermissions();

		return () => {
			memoCancelRequest(ENDPOINT_PATH.GENERAL.PERMISSIONS);
		};
	}, [memoCancelRequest]);

	useEffect(() => {
		if (!isCreate) onHandleGetDetails(id);

		return () => {
			if (id) memoCancelRequest(ENDPOINT_PATH.USER_ACCESS.ROLE);
		};
	}, [isCreate, id, memoCancelRequest, onHandleGetDetails]);

	return (
		<div className="page-user-access-create-edit-role">
			<div className="user-access-create-edit-role">
				<div className="user-access-create-edit-role__header">
					<h1 className="user-access-create-edit-role__title">{title}</h1>

					{!isCreate && (
						<p className="user-access-create-edit-role__last-update">
							<span>Last Updated By</span> {formik.values.lastModifiedBy}, {formik.values.lastModifiedDate}
						</p>
					)}
				</div>

				<form className="user-access-create-edit-role__form" onSubmit={formik.handleSubmit}>
					<div className="user-access-create-edit-role__container">
						<div className="user-access-create-edit-role__row">
							<div className="user-access-create-edit-role__column">
								<AppInput required type="text" name="name" label="Name" placeholder="Enter Name" disabled={restricted} value={formik.values.name} error={formik.errors.name} touched={formik.touched.name} onChange={formik.handleChange} />

								<div className="user-access-create-edit-role__row">
									<div className="user-access-create-edit-role__toggle">
										<div className="user-access-create-edit-role__label"> Login with OTP </div>

										<AppToggleSwitch name="requireOtp" disabled={restricted} checked={formik.values.requireOtp} onChange={formik.handleChange} />
									</div>
								</div>
							</div>

							<AppInput multiline type="textarea" name="description" label="Description" placeholder="Enter description" disabled={restricted} value={formik.values.description} touched={formik.touched.description} onChange={formik.handleChange} />
						</div>
					</div>

					<div className="user-access-create-edit-role__container">
						<div className="roles">
							<div className="roles__header">
								<div className="roles__items">
									<div className="roles__item roles__item--module">
										<p className="roles__label">Module</p>
									</div>

									<div className="roles__permissions-header">
										<div className="roles__action roles__action--view">
											<p className="roles__label">View</p>
										</div>

										<div className="roles__action roles__action--add">
											<p className="roles__label">Add</p>
										</div>

										<div className="roles__action roles__action--edit">
											<p className="roles__label">Edit</p>
										</div>

										<div className="roles__action roles__action--delete">
											<p className="roles__label">Delete</p>
										</div>

										<div className="roles__action roles__action--upload">
											<p className="roles__label">Upload</p>
										</div>

										<div className="roles__action roles__action--all">
											<p className="roles__label"></p>
										</div>
									</div>
								</div>
							</div>

							<div className="roles__body">
								{Object.keys(permissions).map((a) => {
									const title = capitalizeCharacter(a.split("_").join(" "));
									const categoryPermissions = permissions[a];
									const isAllSelected = categoryPermissions.every((group) => group.childs.every((permission) => formik.values.permissions.includes(permission.id)));

									const handleSelectAllClick = () => {
										onHandleSelectAllPermissions(categoryPermissions, !isAllSelected);
									};

									return (
										<div className="roles__accordions" key={a}>
											<div className="roles__category-container">
												<p className="roles__category">{title}</p>

												<AppButton type="button" label={categoryPermissions.every((group) => group.childs.every((permission) => formik.values.permissions.includes(permission.id))) ? "Deselect All" : "Select All"} onClick={handleSelectAllClick} />
											</div>

											<div className="roles__accordion">
												{categoryPermissions.map((b) => {
													const selected = formik.values.permissions;
													const allPermission = b.childs.find((a) => a.name.includes("all"));
													const viewPermission = b.childs.find((a) => a.name.includes("view"));
													const createPermission = b.childs.find((a) => a.name.includes("create"));
													const updatePermission = b.childs.find((a) => a.name.includes("update"));
													const deletePermission = b.childs.find((a) => a.name.includes("delete"));
													const uploadPermission = b.childs.find((a) => a.name.includes("upload"));
													const allSelected = selected.includes(allPermission?.id);
													const viewSelected = selected.includes(viewPermission?.id);
													const createSelected = selected.includes(createPermission?.id);
													const updateSelected = selected.includes(updatePermission?.id);
													const deleteSelected = selected.includes(deletePermission?.id);
													const uploadSelected = selected.includes(uploadPermission?.id);

													return (
														<div className="roles__items" key={b.name}>
															<div className="roles__item roles__item--module">
																<p className="roles__label">{b.title}</p>
															</div>

															<div className="roles__permissions-body">
																<div className="roles__action roles__action--view">{viewPermission && <AppCheckbox label="View" value={viewSelected} disabled={restricted} onClick={() => onHandleSelectPermission(viewPermission)} />}</div>

																<div className="roles__action roles__action--add">{createPermission && <AppCheckbox label="Add" value={createSelected} disabled={restricted} onClick={() => onHandleSelectPermission(createPermission)} />}</div>

																<div className="roles__action roles__action--edit">{updatePermission && <AppCheckbox label="Edit" value={updateSelected} disabled={restricted} onClick={() => onHandleSelectPermission(updatePermission)} />}</div>

																<div className="roles__action roles__action--delete">{deletePermission && <AppCheckbox label="Delete" value={deleteSelected} disabled={restricted} onClick={() => onHandleSelectPermission(deletePermission)} />}</div>

																<div className="roles__action roles__action--upload">{uploadPermission && <AppCheckbox label="Upload" value={uploadSelected} disabled={restricted} onClick={() => onHandleSelectPermission(uploadPermission)} />}</div>

																<div className="roles__action roles__action--upload">{allPermission && <AppCheckbox label="All" value={allSelected} disabled={restricted} onClick={() => onHandleSelectPermission(allPermission)} />}</div>
															</div>
														</div>
													);
												})}
											</div>
										</div>
									);
								})}
							</div>
						</div>
					</div>

					<div className="user-access-create-edit-role__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleBack} />

						<AppButton type="submit" label={submitLabel} disabled={formik.isSubmitting || restricted} />
					</div>
				</form>
			</div>
		</div>
	);
};

export default PageUserAccessCreateEditRole;

PageUserAccessCreateEditRole.propTypes = {
	onHandleCancelRequest: PropTypes.func
};
