import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import api from "services/api";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import validateFileSize from "common/validate-file-size";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import ERRORS from "constants/errors";
import VERIFICATION_TYPE from "constants/verification-type";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";
import AppInputDragAndDropFiles from "components/app-input-drag-and-drop-files";

export const AppInvoicePaidModal = (props, ref) => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const [visible, setVisible] = useState(false);
	const [data, setData] = useState({});
	const onHandleGetDetails = useMemo(() => props.onHandleGetDetails, [props.onHandleGetDetails]);

	const verificationTypeOptions = [
		{ label: "Transaction ID", value: VERIFICATION_TYPE.TRANSACTION_ID },
		{ label: "Upload Transaction Slip", value: VERIFICATION_TYPE.TRANSACTION_SLIP }
	];

	const initialValues = useMemo(() => {
		const values = {
			verificationType: "",
			transactionId: "",
			file: []
		};

		return values;
	}, []);

	//prettier-ignore
	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			verificationType: yup.string().required(ERRORS.REQUIRED),
			transactionId: yup.string().when(["verificationType"], {
				is: (type) => type === VERIFICATION_TYPE.TRANSACTION_ID,
				then: () => yup.string().required(ERRORS.REQUIRED)
			}),
			file: yup.array().when(["verificationType"], {
				is: (type) => type === VERIFICATION_TYPE.TRANSACTION_SLIP,
				then: () => yup.array().of(yup.mixed().test("fileSize", ERRORS.FILE_SIZE, (file) => file && validateFileSize(file))).min(1, ERRORS.REQUIRED).required(ERRORS.REQUIRED)
			})
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;
		let payload = {};

		try {
			if (formik.values.verificationType === VERIFICATION_TYPE.TRANSACTION_ID) {
				payload = {
					"invoice-id": id,
					id: id, 
					type: values.verificationType,
					transactionId: values.transactionId
				};

				response = await api.post.invoice.invoicePaid(payload);
			} else {
				const formData = new FormData();
				const payload = { queryParam: { "invoice-id": id } };

				formData.append("invoice-id", id);
				formData.append("type", values.verificationType);

				values.file.forEach((o) => {
					formData.append("transactionSlip", o);
				});
				
				payload.payload = formData;

				response = await api.post.invoice.invoicePaidMultiPart(payload);
			}
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			onHandleGetDetails(id);

			dispatch(promptLayoutAlertMessage({ message: "Invoice has been successfully paid!" }));

			setVisible(false);
		}
	}, [dispatch, formik.values.verificationType, id, onHandleGetDetails]);

	const onHandleShow = useCallback((value) => {
		setData(value);

		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-invoice-paid-modal" }} open={visible}>
			<div className="invoice-paid-modal">
				<form className="invoice-paid__form" onSubmit={formik.handleSubmit}>
					<h1 className="invoice-paid-modal__title">Paid Invoice</h1>

					<p className="invoice-paid-modal__description">Please fill out the fields below to verify the payment.</p>

					<AppSelectInput required searchable={false} name="verificationType" label="Verification Type" placeholder="Select..." options={verificationTypeOptions} value={formik.values.verificationType} error={formik.errors.verificationType} touched={formik.touched.verificationType} onChange={formik.handleChange} />

					{formik.values.verificationType === VERIFICATION_TYPE.TRANSACTION_ID && <AppInput required type="text" name="transactionId" label="Transaction ID" placeholder="Enter Transaction ID" value={formik.values.transactionId} error={formik.errors.transactionId} touched={formik.touched.transactionId} onChange={formik.handleChange} />}

					{formik.values.verificationType === VERIFICATION_TYPE.TRANSACTION_SLIP && (
						<div className="invoice-paid-modal__file-container">
							<p className="invoice-paid-modal__label">Attachment</p>

							<p className="invoice-paid-modal__instructions">Please ensure that all images are in either jpg, png, or pdf format with file size not exceeding 5MB.</p>

							<AppInputDragAndDropFiles name="file" accept="image/png, image/jpg" value={formik.values.file} onChange={formik.setFieldValue} />
						</div>
					)}

					<p className="invoice-paid-modal__description">
						After clicking confirm, the status for Invoice <br /> <b>{data?.referenceNo}</b> will be set to <span>Paid</span>. <br />
						The balance for Sales Order <b>{data?.saleOrderReferenceNo}</b> will be updated.
					</p>

					<div className="invoice-paid-modal__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

						<AppButton type="submit" label="Confirm" />
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppInvoicePaidModal));

AppInvoicePaidModal.propTypes = {
	ref: PropTypes.object.isRequired,
	onConfirm: PropTypes.func.isRequired
};
