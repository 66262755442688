import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";

import api from "services/api";
import getInventoryFromListing from "services/get-inventory-from-listing";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import ERRORS from "constants/errors";
import STATUS from "constants/status";

import AppIcon from "components/app-icon";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";
import AppInputDateTime from "components/app-input-date-time";
import AppInputDragAndDrop from "components/app-input-drag-and-drop";

import closeIcon from "assets/images/close-icon.png";

export const AppInventoryHistoryViewModal = (props, ref) => {
	const [visible, setVisible] = useState(false);

	//prettier-ignore
	const onHandleSubmit = useCallback((value) => {
		setVisible(false);

		props.onHandleApproveRejectIssue(value, STATUS.APPROVED);
	}, [props]);

	//prettier-ignore
	const formik = useFormik({
		initialValues: {
			id: "",
			inventoryItem: "",
			inventoryId: "",
			status: "",
			requestedBy: "",
			createdDate: "",
			from: "",
			inventoryImage: "",
			workOrderId: "",
			quantity: "",
			availableQuantity: "",
			transactionType: "",
			remark: "",
			reviewedBy: "",
			issuedBy: "",
			receivedBy: "",
			issuedSignature: "",
			receivedSignature: "",
			employeeRequestInventoryDocVo: ""
		},
		validationSchema: yup.object({
			from: yup.string().test("exceedAvailableQuantity", "Request quantity cannot exceed available quantity", function (value, { parent }) {
				return parent.quantity <= parent.availableQuantity;
			}).required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const isPending = useMemo(() => formik.values.status === STATUS.PENDING, [formik]);
	const isApproved = useMemo(() => formik.values.status === STATUS.APPROVED, [formik]);
	const isIssuedReceived = useMemo(() => formik.values.status === STATUS.ISSUED || formik.values.status === STATUS.RECEIVED, [formik]);
	const isReceived = useMemo(() => formik.values.status === STATUS.RECEIVED, [formik]);
	const appInputLabel = useMemo(() => (formik.values.status === STATUS.APPROVED ? "Approved By" : "Rejected By"), [formik]);

	// prettier-ignore
	const onHandleGetDetails = useCallback(async (inventoryId) => {
		let response = null;

		try {
			response = await api.get.inventory.inventoryRequest(inventoryId);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			formik.setValues({
				id: response?.id,
				inventoryItem: response?.inventoryItem,
				inventoryId: response?.inventoryId,
				status: response?.status,
				requestedBy: response?.inventoryHistoryRequestSignature?.submittedBy,
				createdDate: dayjs(response?.createdDate),
				inventoryLocationName: response?.inventoryLocationName,
				workOrderId: response?.workOrderId,
				quantity: response?.quantity,
				inventoryImage: response?.sparePartDocPath,
				from: response?.inventoryLocationId,
				transactionType: response?.transactionType,
				availableQuantity: response?.available,
				remark: response?.inventoryHistoryRequestSignature?.remark,
				reviewedBy: response?.inventoryHistoryRequestSignature?.approvedBy || response?.inventoryHistoryRequestSignature?.rejectedBy,
				issuedBy: response?.inventoryHistoryRequestSignature?.issuedBy,
				receivedBy: response?.employeeRequestInventorySignature?.receivedBy,
				issuedSignature: response?.inventoryHistoryRequestSignatureDoc?.fileUrl,
				receivedSignature: response?.employeeRequestInventorySignatureDoc?.fileUrl,
				employeeRequestInventoryDocVo: response?.employeeRequestInventoryDoc?.fileUrl
			});		
		}
	}, [formik]);

	//prettier-ignore
	const onHandleShow = useCallback((id) => {
		onHandleGetDetails(id);

		setVisible(true);
	}, [onHandleGetDetails]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);

		formik.resetForm();
	}, [formik]);

	const onHandleReject = useCallback(() => {
		setVisible(false);

		props.onHandleApproveRejectIssue(formik.values, STATUS.REJECTED);
	}, [formik, props]);

	const onHandleIssue = useCallback(() => {
		setVisible(false);

		props.onHandleApproveRejectIssue(formik.values, STATUS.ISSUED);
	}, [formik, props]);

	//prettier-ignore
	const onHandleSelectFrom = useCallback(async (event) => {
		const value = event.target.value;

		try {
			formik.handleChange(value);

			onHandleGetDetails(formik.values.id)
		} catch (error) {
			serveLayoutRequestErrors(error);
		}
	}, [formik, onHandleGetDetails]);

	const FooterButton = useCallback((obj) => {
		if (obj.status !== STATUS.PENDING && obj.status !== STATUS.APPROVED) {
			return null;
		}

		return (
			<div className="inventory-history-view-modal__button-container">
				<div className="inventory-history-view-modal__reject-button">
					<AppButton outline type="button" label="Reject" onClick={obj.onHandleReject} />
				</div>

				{obj.isPending && (
					<div className="inventory-history-view-modal__approve-button">
						<AppButton type="submit" label="Approve" />
					</div>
				)}

				{obj.isApproved && (
					<div className="inventory-history-view-modal__issue-button">
						<AppButton type="button" label="Issue" onClick={obj.onHandleIssue} />
					</div>
				)}
			</div>
		);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-inventory-history-view-modal" }} open={visible}>
			<div className="inventory-history-view-modal">
				<button type="button" className="inventory-history-view-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<h1 className="inventory-history-view-modal__title">Item Request</h1>

				<div className="inventory-history-view-modal__image">{formik.values.inventoryImage && <img src={formik.values.inventoryImage} alt="inventory" />}</div>

				<form className="inventory-history-view-modal__form" onSubmit={formik.handleSubmit}>
					<div className="inventory-history-view-modal__row">
						<AppInput disabled type="text" name="inventoryItem" label="Inventory Item" value={formik.values.inventoryItem} error={formik.errors.inventoryItem} touched={formik.touched.inventoryItem} onChange={formik.handleChange} />

						<AppInput disabled type="text" name="status" label="Status" value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />
					</div>

					<div className="inventory-history-view-modal__row">
						<AppInput disabled type="text" name="requestedBy" label="Requested By" value={formik.values.requestedBy} error={formik.errors.requestedBy} touched={formik.touched.requestedBy} onChange={formik.handleChange} />

						<AppInputDateTime disabled type="text" name="createdDate" label="Date & Time" value={formik.values.createdDate} error={formik.errors.createdDate} touched={formik.touched.createdDate} onChange={formik.handleChange} />
					</div>

					<div className="inventory-history-view-modal__row">
						<AppSelectInput disabled={!isApproved} searchable={false} pagination name="from" label="From" placeholder="Select..." loadOptions={(payload) => getInventoryFromListing(payload, formik.values.inventoryId)} value={formik.values.from} error={formik.errors.from} touched={formik.touched.from} onChange={onHandleSelectFrom} />

						<AppInput disabled type="text" name="workOrderId" label="Work Order ID" value={formik.values.workOrderId} error={formik.errors.workOrderId} touched={formik.touched.workOrderId} onChange={formik.handleChange} />
					</div>

					<div className="inventory-history-view-modal__row">
						<AppInput disabled type="text" name="quantity" label="Request Quantity" value={formik.values.quantity} error={formik.errors.quantity} touched={formik.touched.quantity} onChange={formik.handleChange} />

						<AppInput disabled type="text" name="availableQuantity" label="Available Quantity" value={formik.values.availableQuantity} error={formik.errors.availableQuantity} touched={formik.touched.availableQuantity} onChange={formik.handleChange} />
					</div>

					<div className="inventory-history-view-modal__row">
						{/* prettier-ignore */}
						<AppSelectInput disabled={!isApproved} type="text" name="transactionType" label="Transaction Type" options={[{ value: "REQUEST", label: "Request" }, { value: "NEW_PURCHASE", label: "New Purchase" }]} value={formik.values.transactionType} error={formik.errors.transactionType} touched={formik.touched.transactionType} onChange={formik.handleChange} />

						{!isPending && <AppInput disabled type="text" name="reviewedBy" label={appInputLabel} value={formik.values.reviewedBy} error={formik.errors.reviewedBy} touched={formik.touched.reviewedBy} onChange={formik.handleChange} />}
					</div>

					<AppInput disabled type="textarea" name="remark" label="Remarks" multiline maxLength={255} value={formik.values.remark} touched={formik.touched.remark} error={formik.errors.remark} onChange={formik.handleChange} />

					{isIssuedReceived && (
						<div className="inventory-history-view-modal__row">
							<div className="inventory-history-view-modal__signature">
								<AppInput disabled type="text" name="issuedBy" label="Issued By" value={formik.values.reviewedBy} error={formik.errors.issuedBy} touched={formik.touched.issuedBy} onChange={formik.handleChange} />

								{!!formik.values.issuedSignature && <AppInputDragAndDrop disabled name="issuedSignature" accept="image/png, image/jpeg, image/jpg, .doc, .docx, .pdf" progress="100%" value={formik.values.issuedSignature} onChange={formik.setFieldValue} error={formik.errors.issuedSignature} touched={formik.touched.issuedSignature} />}
							</div>

							{isReceived && (
								<div className="inventory-history-view-modal__signature">
									<AppInput disabled type="text" name="receivedBy" label="Received By" value={formik.values.receivedBy} error={formik.errors.receivedBy} touched={formik.touched.receivedBy} onChange={formik.handleChange} />

									{!!formik.values.receivedSignature && <AppInputDragAndDrop disabled name="receivedSignature" accept="image/png, image/jpeg, image/jpg,.doc,.docx,.pdf" progress="75%" value={formik.values.receivedSignature} onChange={formik.setFieldValue} error={formik.errors.receivedSignature} touched={formik.touched.receivedSignature} />}

									<div className="inventory-history-view-modal__image">
										<img src={formik.values?.employeeRequestInventoryDocVo} alt="received" />
									</div>
								</div>
							)}
						</div>
					)}

					<FooterButton status={formik.values.status} onHandleReject={onHandleReject} onHandleIssue={onHandleIssue} isApproved={isApproved} isPending={isPending} />
				</form>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppInventoryHistoryViewModal));

AppInventoryHistoryViewModal.propTypes = {
	onHandleApproveRejectIssue: PropTypes.func
};
