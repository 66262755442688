import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import pathnames from "routes/pathnames";

import api from "services/api";
import getStateListing from "services/get-state-listing";
import getCitiesListing from "services/get-region-listing";
import getUnassignedWorkCompletionListing from "services/get-unassigned-work-completion-listing";
import getPostcodeListing from "services/get-postcode-listing";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import sanitizeObject from "common/sanitize-object";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import PAGE from "constants/page";
import ROLES from "constants/roles";
import ERRORS from "constants/errors";
import STATUS from "constants/status";
import ENDPOINT_PATH from "constants/end-point-path";
import SIGNATURE_TYPE from "constants/signature-type";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppInputDate from "components/app-input-date";
import AppInputTime from "components/app-input-time";
import AppMobileInput from "components/app-mobile-input";
import AppSelectInput from "components/app-select-input";
import AppInputDragAndDrop from "components/app-input-drag-and-drop";
import AppAssetConfirmationModal from "components/pages/work-completion-report/app-asset-confirmation-modal";

const AppWorkCompletionReportDetails = (props) => {
	const navigate = useNavigate();
	const { id } = useParams();
	const dispatch = useDispatch();
	const profile = useSelector((state) => state.profile);
	const wcrInfo = useMemo(() => props.wcrInfo, [props.wcrInfo]);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.WORK_COMPLETION], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const submitLabel = useMemo(() => (isCreate ? "Next" : "Update"), [isCreate]);
	const [signatureType, setSignatureType] = useState("");
	const confirmationRef = useRef();
	const preparedByNameKeyword = useRef();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;

	const initialValues = useMemo(() => {
		const values = {
			id: "",
			reportId: "",
			status: STATUS.DRAFT,
			workOrderId: "",
			salesOrderId: "",
			workInspectionReportId: "",
			registeredName: "",
			customerPic: "",
			contractId: "",
			siteId: "",
			assetId: "",
			customerAssetName: "",
			prefixNo: "",
			mobileNo: "",
			email: "",
			addressLine1: "",
			addressLine2: "",
			city: "",
			state: "",
			postcode: "",
			reportTitle: "",
			scopeOfWork: "",
			completionDate: "",
			completionTime: "",
			preparedBy: { employeeId: "", name: "", signature: "", signatureFile: null },
			completedBy: { employeeId: "", name: "", signature: "", signatureFile: null },
			acknowledgedBy: { employeeId: "", name: "", signature: "", signatureFile: null }
		};

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			workOrderId: yup.string().required(ERRORS.REQUIRED),
			reportTitle: yup.string().required(ERRORS.REQUIRED),
			scopeOfWork: yup.string().required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);
	const isEditable = useMemo(() => formik.values.status === STATUS.DRAFT, [formik.values.status]);
	const isPendingAcknowledgeSignature = useMemo(() => formik.values.status === STATUS.DRAFT && !isCreate, [formik.values.status, isCreate]);
	const getCitiesLoadOptions = useCallback(() => getCitiesListing({ state: formik.values.state }), [formik.values.state]);
	const getPostcodeLoadOptions = useCallback(() => getPostcodeListing(`${formik.values.state}/${formik.values.city}`), [formik.values.state, formik.values.city]);

	//prettier-ignore
	const onHandleGetEmployeeDetail = useCallback(async (uniqueId) => {
		let response = null;

		try {
			const params = sanitizeObject({ "user-id": uniqueId });

			response = await api.get.humanResource.employeeHR(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			if (isCreate) memoSetFormValues((prev) => ({ ...prev, preparedBy: { employeeId: response?.id, name: response?.fullName } }));

			if (isPendingAcknowledgeSignature) memoSetFormValues((prev) => ({ ...prev, acknowledgedBy: { employeeId: response?.id, name: response?.fullName } }));

			preparedByNameKeyword.current = response.fullName;
		}
	}, [isCreate, isPendingAcknowledgeSignature, memoSetFormValues]);

	const onHandleGetDetails = useCallback(() => {
		memoSetFormValues({
			id: wcrInfo.id,
			reportId: wcrInfo.referenceNo,
			status: wcrInfo.status,
			workOrderId: wcrInfo.workOrder?.id,
			salesOrderId: wcrInfo.saleOrder?.id,
			salesOrderReferenceNo: wcrInfo.saleOrder?.referenceNo,
			workInspectionReportId: wcrInfo.workInspection?.referenceNo,
			registeredName: wcrInfo.workOrderCustomerDetail?.customer?.registeredName,
			customerPic: wcrInfo.workOrderCustomerDetail?.customerPicContact?.picName,
			contractId: wcrInfo.workOrderCustomerDetail?.customerContract?.referenceNo,
			siteId: wcrInfo.workOrderCustomerDetail?.customerContractSite?.referenceNo,
			assetId: wcrInfo?.woAsset?.[0]?.assetName ?? "",
			customerAssetName: wcrInfo?.woAsset?.[0]?.customerAssetName ?? "",
			prefixNo: wcrInfo.workOrderCustomerDetail?.customerPicContact?.mobileNoPrefix,
			mobileNo: wcrInfo.workOrderCustomerDetail?.customerPicContact?.mobileNo,
			email: wcrInfo.workOrderCustomerDetail?.customerPicContact?.email,
			addressLine1: wcrInfo.workOrderCustomerDetail?.address_1,
			addressLine2: wcrInfo.workOrderCustomerDetail?.address_2,
			city: wcrInfo.workOrderCustomerDetail?.city,
			state: wcrInfo.workOrderCustomerDetail?.state,
			postcode: wcrInfo.workOrderCustomerDetail?.postcode,
			reportTitle: wcrInfo.reportTitle,
			scopeOfWork: wcrInfo.scopeOfWork,
			completionDate: wcrInfo?.completionDate ? dayjs(wcrInfo.completionDate) : "",
			completionTime: wcrInfo?.completionDate ? dayjs(wcrInfo?.completionDate) : "",
			preparedBy: { employeeId: wcrInfo.signature?.preparedBy?.id, name: wcrInfo.signature?.preparedBy?.personalInfo?.fullName, signature: wcrInfo.signature?.preparedBySignaturePath },
			verifiedBy: { employeeId: wcrInfo.signature?.verifiedBy?.id, name: wcrInfo.signature?.verifiedBy?.personalInfo?.fullName, signature: wcrInfo.signature?.verifiedBySignaturePath },
			acknowledgedBy: { employeeId: wcrInfo.signature?.acknowledgedBy?.id, name: wcrInfo.signature?.acknowledgedBy?.personalInfo?.fullName, signature: wcrInfo.signature?.acknowledgedBySignaturePath }
		});

		preparedByNameKeyword.current = wcrInfo.signature?.preparedBy?.personalInfo?.fullName;
	}, [memoSetFormValues, wcrInfo]);

	//prettier-ignore
	const onHandleSetSignature = useCallback((name, value, signatureType) => {
		setSignatureType(signatureType);

		let image = "";
		let signatureName = `${name.split(".")[0]}.signatureFile`;

		if (value) {
			image = URL.createObjectURL(value);
		}

		formik.setFieldValue(name, image);
		formik.setFieldValue(signatureName, value);
	}, [formik]);

	const onHandleGetRelevantSignature = useCallback(() => {
		let signatureObject = {};

		switch (signatureType) {
			case SIGNATURE_TYPE.PREPARED_BY:
				signatureObject = formik.values.preparedBy;
				break;
			case SIGNATURE_TYPE.VERIFIED_BY:
				signatureObject = formik.values.verifiedBy;
				break;
			case SIGNATURE_TYPE.ACKNOWLEDGED_BY:
				signatureObject = formik.values.acknowledgedBy;
				break;
			case SIGNATURE_TYPE.CANCELLED_BY:
				signatureObject = formik.values.cancelledBy;
				break;
			default:
				break;
		}

		return signatureObject;
	}, [formik.values, signatureType]);

	//prettier-ignore
	const onHandleSignatures = useCallback(async (workCompletionId) => {
		let response = null;

		if (!signatureType) return;

		const signatureObj = onHandleGetRelevantSignature();

		try {
			const formData = new FormData();

			formData.append("file", signatureObj.signatureFile);
			formData.append("wcrId", workCompletionId);
			formData.append("employeeCustomerId", signatureObj.employeeId);
			formData.append("signatureType", signatureType);

			await api.post.workCompletionReport.signatureUpload(formData);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			if (isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Signature was added successfully!" }));
			}

			if (!isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Signature was updated successfully!" }));
			}
		}
	}, [dispatch, isCreate, onHandleGetRelevantSignature, signatureType]);

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			const payload = {
				workOrderId: values.workOrderId,
				saleOrderId: values.salesOrderId,
				reportTitle: values.reportTitle,
				scopeOfWork: values.scopeOfWork
			};

			if (isCreate) {
				response = await api.post.workCompletionReport.createWorkCompletionReport(payload);
			}

			if (!isCreate) {
				payload.id = id;

				response = await api.post.workCompletionReport.updateWorkCompletionReport(payload);
			}
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			if (isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Work Completion Report was added successfully!" }));
			}

			if (!isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Work Completion Report was updated successfully!" }));
			}

			onHandleSignatures(response.id);

			navigate(pathnames.workCompletionReport.workCompletionReport);
		}
	}, [isCreate, id, formik, onHandleSignatures, navigate, dispatch]);

	// prettier-ignore
	const onHandleSelectWorkOrder = useCallback(async (event) => {
		const value = event.target.value;

		const currentValues = structuredClone(formik.values);

		const woData = await api.get.workOrder.workOrder(value);

		formik.setValues({
			...currentValues,
			workOrderId: value,
			registeredName: woData.customerDetail.customer.registeredName,
			customerPic: woData.customerDetail.customerPicContact.picName,
			contractId: woData.customerDetail.customerContract.referenceNo,
			siteId: woData.customerDetail.customerContractSite.name,
			assetId: woData.woAsset[0].assetName,
			customerAssetName: woData.woAsset[0].customerAssetName,
			mobileNoPrefix: woData.customerDetail.customerPicContact.mobileNoPrefix,
			mobileNo: woData.customerDetail.customerPicContact.mobileNo,
			email: woData.customerDetail.customerPicContact.email,
			addressLine1: woData.customerDetail.address_1,
			addressLine2: woData.customerDetail.address_2,
			state: woData.customerDetail.state,
			city: woData.customerDetail.city,
			postcode: woData.customerDetail.postcode,
			completionDate: dayjs(woData.endDate) || "",
			completionTime: dayjs(woData.endDate) || ""
		});
	}, [formik]);

	const onHandleBack = useCallback(() => {
		navigate(pathnames.workCompletionReport.workCompletionReport);
	}, [navigate]);

	useEffect(() => {
		if (isCreate || isPendingAcknowledgeSignature) onHandleGetEmployeeDetail(profile.id);

		if (!isCreate) onHandleGetDetails();
	}, [isCreate, isPendingAcknowledgeSignature, onHandleGetDetails, onHandleGetEmployeeDetail, profile.id]);

	useEffect(() => {
		return () => {
			if (isCreate || isPendingAcknowledgeSignature) cancelRequest(ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEE_HR);
		};
	}, [cancelRequest, isCreate, isPendingAcknowledgeSignature]);

	return (
		<div className="app-work-completion-report-details">
			<div className="work-completion-report-details">
				<form className="work-completion-report-details__form" onSubmit={formik.handleSubmit}>
					<div className="work-completion-report-details__container">
						<p className="work-completion-report-details__label">General Details</p>

						<div className="work-completion-report-details__row">
							<AppInput disabled type="text" name="reportId" label="Report ID" placeholder="" value={formik.values.reportId} error={formik.errors.reportId} touched={formik.touched.reportId} onChange={formik.handleChange} />

							<AppInput disabled type="text" name="status" label="Status" placeholder="Select..." value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />
						</div>

						<div className="work-completion-report-details__row">
							<AppSelectInput disabled={!isEditable} required searchable={false} type="text" name="workOrderId" label="Work Order ID" placeholder="Select..." loadOptions={(payload) => getUnassignedWorkCompletionListing({wcrId: id, keyword: payload?.keyword})} value={formik.values.workOrderId} error={formik.errors.workOrderId} touched={formik.touched.workOrderId} onChange={onHandleSelectWorkOrder} />

							<AppInput disabled type="text" name="salesOrderReferenceNo" label="Sales Order ID" value={formik.values.salesOrderReferenceNo} touched={formik.touched.salesOrderReferenceNo} onChange={formik.handleChange} />
						</div>

						<div className="work-completion-report-details__row work-completion-report-details__row--divider">
							<AppInput disabled type="text" name="workInspectionReportId" label="Work Inspection Report ID" placeholder="" value={formik.values.workInspectionReportId} error={formik.errors.workInspectionReportId} touched={formik.touched.workInspectionReportId} onChange={formik.handleChange} />
						</div>

						<p className="work-completion-report-details__label">Customer Details</p>

						<div className="work-completion-report-details__row">
							<AppInput disabled type="text" name="registeredName" label="Registered Name" placeholder="Registered Name" value={formik.values.registeredName} error={formik.errors.registeredName} touched={formik.touched.registeredName} onChange={formik.handleChange} />

							<AppInput disabled type="text" name="customerPic" label="Customer PIC" placeholder="" value={formik.values.customerPic} error={formik.errors.customerPic} touched={formik.touched.customerPic} onChange={formik.handleChange} />
						</div>

						<div className="work-completion-report-details__row">
							<AppInput disabled type="text" name="contractId" label="Contract ID" placeholder="" value={formik.values.contractId} error={formik.errors.contractId} touched={formik.touched.contractId} onChange={formik.handleChange} />

							<AppInput disabled type="text" name="siteId" label="Site Name" placeholder="" value={formik.values.siteId} error={formik.errors.siteId} touched={formik.touched.siteId} onChange={formik.handleChange} />
						</div>

						<div className="work-completion-report-details__row">
							<AppInput disabled type="text" name="assetId" label="Asset Name" placeholder="" value={formik.values.assetId} error={formik.errors.assetId} touched={formik.touched.assetId} onChange={formik.handleChange} />

							<AppInput disabled type="text" name="customerAssetName" label="Customer Asset Name" placeholder="Customer Asset Name" value={formik.values.customerAssetName} error={formik.errors.customerAssetName} touched={formik.touched.customerAssetName} onChange={formik.handleChange} />
						</div>

						<div className="work-completion-report-details__row">
							<AppMobileInput disabled type="number" name="mobileNo" label="Mobile No." value={formik.values.mobileNo} prefixNo={formik.values.prefixNo} error={formik.errors.mobileNo} touched={formik.touched.mobileNo} onChange={formik.handleChange} onChangeCode={formik.setFieldValue} />

							<AppInput disabled type="text" name="email" label="Email" placeholder="" value={formik.values.email} error={formik.errors.email} touched={formik.touched.email} onChange={formik.handleChange} />
						</div>

						<p className="work-completion-report-details__label">Site Location</p>

						<div className="work-completion-report-details__row">
							<AppInput disabled type="text" name="addressLine1" label="Address Line 1" placeholder="Address Line 1" value={formik.values.addressLine1} error={formik.errors.addressLine1} touched={formik.touched.addressLine1} onChange={formik.handleChange} />

							<AppInput disabled type="text" name="addressLine2" label="Address Line 2" placeholder="Address Line 2" value={formik.values.addressLine2} error={formik.errors.addressLine2} touched={formik.touched.addressLine2} onChange={formik.handleChange} />
						</div>

						<div className="work-completion-report-details__row">
							<AppSelectInput disabled name="state" label="State" placeholder="State" loadOptions={getStateListing} value={formik.values.state} error={formik.errors.state} touched={formik.touched.state} onChange={formik.handleChange} />

							<AppSelectInput disabled name="city" label="City" placeholder="City" loadOptions={getCitiesLoadOptions} value={formik.values.city} error={formik.errors.city} touched={formik.touched.city} onChange={formik.handleChange} />
						</div>

						<div className="work-completion-report-details__row">
							<AppSelectInput disabled name="postcode" label="Postcode" placeholder="Postcode" loadOptions={getPostcodeLoadOptions} value={formik.values.postcode} error={formik.errors.postcode} touched={formik.touched.postcode} onChange={formik.handleChange} />
						</div>

						<p className="work-completion-report-details__label">Content</p>

						<AppInput disabled={restricted || !isEditable} required type="text" name="reportTitle" label="Report Title" placeholder="Enter Report Title" value={formik.values.reportTitle} error={formik.errors.reportTitle} touched={formik.touched.reportTitle} onChange={formik.handleChange} />

						<AppInput disabled={restricted || !isEditable} required multiline type="textarea" name="scopeOfWork" label="Scope of Work" placeholder="Enter Scope of Work" value={formik.values.scopeOfWork} error={formik.errors.scopeOfWork} touched={formik.touched.scopeOfWork} onChange={formik.handleChange} />

						<div className="work-completion-report-details__row">
							<AppInputDate disabled name="completionDate" label="Completion Date" placeholder="DD/MM/YYYY" value={formik.values.completionDate} error={formik.errors.completionDate} touched={formik.touched.completionDate} onChange={formik.setFieldValue} />

							<AppInputTime disabled name="completionTime" label="Completion Time" placeholder="HH:MM" value={formik.values.completionTime} error={formik.errors.completionTime} touched={formik.touched.completionTime} onChange={formik.setFieldValue} />
						</div>
					</div>

					<div className="work-completion-report-details__container">
						<div className="work-completion-report-details__row work-completion-report-details__row--signature">
							<div className="work-completion-report-details__column">
								<AppInput disabled name="preparedBy.name" label="Prepared By" value={formik.values.preparedBy?.name} error={formik.errors.preparedBy?.name} touched={formik.touched.preparedBy?.name} onChange={formik.handleChange} />

								<AppInputDragAndDrop name="preparedBy.signature" accept="image/png, image/jpeg, image/jpg" value={formik.values.preparedBy?.signature} error={formik.errors.preparedBy?.signature} touched={formik.touched.preparedBy?.signature} onChange={(name, file) => onHandleSetSignature(name, file, SIGNATURE_TYPE.PREPARED_BY)} />
							</div>

							<div className="work-completion-report-details__column">
								<AppInput disabled name="acknowledgedBy.name" label="Acknowledged By" value={formik.values.acknowledgedBy?.name} error={formik.errors.acknowledgedBy?.name} touched={formik.touched.acknowledgedBy?.name} onChange={formik.handleChange} />

								<AppInputDragAndDrop disabled={!isPendingAcknowledgeSignature} name="acknowledgedBy.signature" accept="image/png, image/jpeg, image/jpg" value={formik.values.acknowledgedBy?.signature} error={formik.errors.acknowledgedBy?.signature} touched={formik.touched.acknowledgedBy?.signature} onChange={(name, file) => onHandleSetSignature(name, file, SIGNATURE_TYPE.ACKNOWLEDGED_BY)} />
							</div>
						</div>

						<div className="work-completion-report-details__row work-completion-report-details__row--signature">
							<div className="work-completion-report-details__column">
								<AppInput disabled name="verifiedBy.employeeId" label="Verified By" value={formik.values.verifiedBy?.employeeId} error={formik.errors.verifiedBy?.employeeId} touched={formik.touched.verifiedBy?.employeeId} onChange={formik.handleChange} />

								<AppInputDragAndDrop disabled name="verifiedBy.signature" accept="image/png, image/jpeg, image/jpg" value={formik.values.verifiedBy?.signature} error={formik.errors.verifiedBy?.signature} touched={formik.touched.verifiedBy?.signature} onChange={(name, file) => onHandleSetSignature(name, file, SIGNATURE_TYPE.VERIFIED_BY)} />
							</div>
						</div>
					</div>

					<div className="work-completion-report-details__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleBack} />

						<AppButton type="submit" label={submitLabel} disabled={formik.isSubmitting} />
					</div>
				</form>
			</div>

			<AppAssetConfirmationModal ref={confirmationRef} />
		</div>
	);
};
export default AppWorkCompletionReportDetails;
