import React, { Fragment, forwardRef, memo, useCallback, useContext, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import pathnames from "routes/pathnames";

import api from "services/api";
import getStateListing from "services/get-state-listing";
import getRegionListing from "services/get-region-listing";
import getPostcodeListing from "services/get-postcode-listing";
import getCustomerPicListing from "services/get-customer-pic-listing";
import getBuildingTypeListing from "services/get-building-type-listing";
import getContractSitesListing from "services/get-contract-sites-listing";
import getContractByPicListing from "services/get-contract-by-pic-listing";
import getCustomerLookupListing from "services/get-customer-lookup-listing";
import getEmployeeMaintenanceListing from "services/get-employee-maintenance-listing";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import sanitizeObject from "common/sanitize-object";
import combineDateTime from "common/combine-date-time";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import PAGE from "constants/page";
import ROLES from "constants/roles";
import ERRORS from "constants/errors";
import STATUS from "constants/status";
import DATE_TIME from "constants/date-time";
import CONTRACT_TYPE from "constants/contract-type";
import ENDPOINT_PATH from "constants/end-point-path";
import SIGNATURE_TYPE from "constants/signature-type";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppInputDate from "components/app-input-date";
import AppInputTime from "components/app-input-time";
import AppRadioInput from "components/app-radio-input";
import AppMobileInput from "components/app-mobile-input";
import AppSelectInput from "components/app-select-input";
import AppInputDragAndDrop from "components/app-input-drag-and-drop";
import AppReportDetailsAssetTable from "components/pages/work-inspection-listing/app-report-details-asset-table";
import AppReportDetailsQuotationsTable from "components/pages/work-inspection-listing/app-report-details-quotations-table";
import AppReportDetailsTeamMemberTable from "components/pages/work-inspection-listing/app-report-details-team-member-table";

const AppReportDetails = (props, ref) => {
	const navigate = useNavigate();
	const { id } = useParams();
	const dispatch = useDispatch();
	const [data, setData] = useState([]);
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const profile = useSelector((state) => state.profile);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.CUSTOMER_MAINTENANCE], [profile]); // TODO: Change when WIR Role is available
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const submitLabel = useMemo(() => (isCreate ? "Create" : "Update"), [isCreate]);

	//prettier-ignore
	const contractTypeOptions = useMemo(() => [
		{ label: "Contracted", value: CONTRACT_TYPE.CONTRACTED },
		{ label: "Non-Contracted", value: CONTRACT_TYPE.NON_CONTRACTED }
	], []);

	const initialValues = useMemo(() => {
		const values = {
			id: "",
			city: "",
			type: CONTRACT_TYPE.CONTRACTED,
			state: "",
			status: STATUS.DRAFT,
			teamPIC: "",
			endDate: "",
			endTime: "",
			referenceNo: "",
			siteId: "",
			siteName: "",
			postcode: "",
			duration: "",
			startDate: "",
			startTime: "",
			targetCompletionDate: "",
			targetCompletionTime: "",
			customerPic: "",
			customerPicId: "",
			contractId: "",
			reportTitle: "",
			buildingType: "",
			addressLine1: "",
			addressLine2: "",
			email: "",
			prefixNo: "",
			mobileNo: "",
			scheduledDate: "",
			scheduledTime: "",
			planningAction: "",
			customerId: "",
			registeredName: "",
			inspectionDetails: "",
			reasonForReschedule: "",
			reasonForCancellation: "",
			wirAssets: [],
			teamMembers: [],
			preparedBy: { employeeId: "", name: "", signature: "", signatureFile: null },
			completedBy: { employeeId: "", name: "", signature: "", signatureFile: null },
			verifiedBy: { employeeId: "", name: "", signature: "", signatureFile: null },
			acknowledgedBy: { employeeId: "", name: "", signature: "", signatureFile: null },
			rescheduledBy: { employeeId: "", name: "", signature: "", signatureFile: null },
			cancelledBy: { employeeId: "", name: "", signature: "", signatureFile: null },
			pendingRescheduledBy: { employeeId: "", name: "", signature: "", signatureFile: null }
		};

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			type: yup.string().required(ERRORS.REQUIRED),
			customerId: yup.string().required(ERRORS.REQUIRED),
			customerPicId: yup.string().required(ERRORS.REQUIRED),
			addressLine1: yup.string().required(ERRORS.REQUIRED),
			scheduledDate: yup.date().required(ERRORS.REQUIRED).typeError(ERRORS.REQUIRED),
			scheduledTime: yup.date().required(ERRORS.REQUIRED).typeError(ERRORS.REQUIRED),
			state: yup.string().required(ERRORS.REQUIRED),
			city: yup.string().required(ERRORS.REQUIRED),
			postcode: yup.string().required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);
	const memoSetFieldValue = useMemo(() => formik.setFieldValue, [formik.setFieldValue]);
	const isCancelled = useMemo(() => formik.values.status === STATUS.CANCELLED, [formik]);
	const isPendingRescheduled = useMemo(() => formik.values.status === STATUS.PENDING_RESCHEDULE, [formik]);
	const isRescheduled = useMemo(() => formik.values.status === STATUS.RESCHEDULED, [formik]);
	const isVerified = useMemo(() => formik.values.status === STATUS.VERIFIED, [formik]);
	const isBeforeInProgress = useMemo(() => formik.values.status === STATUS.DRAFT || formik.values.status === STATUS.PENDING_ASSIGN || formik.values.status === STATUS.PENDING_ASSET || formik.values.status === STATUS.OPEN, [formik]);
	const isPendingAcknowledge = useMemo(() => formik.values.status === STATUS.PENDING_ACKNOWLEDGE, [formik]);
	const isContract = useMemo(() => formik.values.type === CONTRACT_TYPE.CONTRACTED, [formik]);
	const registeredNameKeyword = useRef(formik.values?.registeredName);
	const siteNameKeyword = useRef();
	const customerKeyword = useRef();
	const preparedByKeyword = useRef();
	const teamPicNameKeyword = useRef();
	const contractNameKeyword = useRef();

	//prettier-ignore
	const onHandleGetEmployeeDetail = useCallback(async (uniqueId) => {
		let response = null;

		try {
			const params = sanitizeObject({ "user-id": uniqueId });

			response = await api.get.humanResource.employeeHR(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			if (isCreate) memoSetFormValues((prev) => ({ ...prev, preparedBy: { employeeId: response?.id, name: response?.fullName } }));

			if (isPendingAcknowledge) memoSetFormValues((prev) => ({ ...prev, acknowledgedBy: { employeeId: response?.id, name: response?.fullName } }));
		}
	}, [isCreate, isPendingAcknowledge, memoSetFormValues]);

	//prettier-ignore
	const onHandleUpdateWIRAssets = useCallback((nextWIRAssets) => {
		memoSetFieldValue("wirAssets", nextWIRAssets);
	}, [memoSetFieldValue]);

	//prettier-ignore
	const onHandleGetList = useCallback(async (id) => {
		let response = null;

		const params = sanitizeObject({ page: 0, size: 10, "work-inspection-id": id });

		try {
			response = await api.get.workInspectionReport.teamMembers(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const updatedTeamMembers = response.content.map((o) => ({
				id: o.id
			}));

			memoSetFieldValue("teamMembers", updatedTeamMembers);
		}
	}, [memoSetFieldValue]);

	//prettier-ignore
	const onHandleGetDetails = useCallback(async (workInspectionReportId) => {
		let response = null;

		try {
			response = await api.get.workInspectionReport.report(workInspectionReportId);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			memoSetFormValues({
				id: response.id,
				city: response.customerDetail?.city,
				type: response.contractType,
				state: response.customerDetail?.state,
				status: response.status,
				teamPIC: response.teamPic?.id || "",
				endDate: dayjs(response.endDate) || "",
				endTime: dayjs(response.endDate) || "",
				referenceNo: response.referenceNo,
				siteId: response.customerDetail?.customerContractSite?.id,
				siteName: response.customerDetail?.customerContractSiteName,
				postcode: response.customerDetail?.postcode,
				startDate: dayjs(response.startDate) || "",
				startTime: dayjs(response.startDate) || "",
				targetCompletionDate: dayjs(response.targetCompletionDate) || "",
				targetCompletionTime: dayjs(response.targetCompletionDate) || "",
				customerPic: response.customerDetail?.customerPicContact.picName,
				customerPicId: response.customerDetail?.customerPicContact?.id,
				contractId: response.customerDetail?.customerContract?.id,
				reportTitle: response.title,
				buildingType: response.customerDetail?.configBuildingType?.id,
				addressLine1: response.customerDetail?.address_1,
				addressLine2: response.customerDetail?.address_2,
				email: response.customerDetail?.customerPicContact.email,
				prefixNo: response.customerDetail?.customerPicContact.mobileNoPrefix,
				mobileNo: response.customerDetail?.customerPicContact.mobileNo,
				scheduledDate: dayjs(response.scheduledDate) || "",
				scheduledTime: dayjs(response.scheduledDate) || "",
				planningAction: response.planningAction,
				customerId: response.customerDetail?.customer.id,
				registeredName: response.customerDetail?.customer.registeredName,
				inspectionDetails: response.inspectionProcedure,
				reasonForReschedule: response.rescheduledReason,
				reasonForCancellation: response.signature?.cancelledReason,
				preparedBy: { employeeId: response.signature?.preparedBy?.id, name: response.signature?.preparedBy?.personalInfo?.fullName, signature: response.signature?.preparedBySignaturePath },
				completedBy: { employeeId: response.signature?.completedBy?.id, name: response.signature?.completedBy?.personalInfo?.fullName, signature: response.signature?.completedBySignaturePath },
				verifiedBy: { employeeId: response.signature?.verifiedBy?.id, name: response.signature?.verifiedBy?.picName, signature: response.signature?.verifiedBySignaturePath },
				acknowledgedBy: { employeeId: response.signature?.acknowledgedBy?.id, name: response.signature?.acknowledgedBy?.personalInfo?.fullName, signature: response.signature?.acknowledgedBySignaturePath },
				rescheduledBy: { employeeId: response.signature?.rescheduledBy?.id, name: response.signature?.rescheduledBy?.personalInfo?.fullName, signature: response.signature?.rescheduledBySignaturePath },
				cancelledBy: { employeeId: response.signature?.cancelledBy?.id, name: response.signature?.cancelledBy?.personalInfo?.fullName, signature: response.signature?.cancelledBySignaturePath },
				pendingRescheduledBy: { employeeId: response?.signature?.pendingRescheduledBy?.id, name: response.signature?.pendingRescheduledBy?.personalInfo?.fullName, signature: response.signature?.pendingRescheduledBySignaturePath }
			});

			const wirAssets = response.wirAsset?.map((o) => ({
				id: o.id,
				customerContractAssetId: o.customerContractSiteAssetId,
				referenceNo: o.referenceNo,
				customerAssetName: o.customerAssetName,
				assetName: o.assetName,
				assetType: o.configAssetType.name,
				workspace: o.configWorkspace.name,
				configAssetTypeId: o.configAssetType.id,
				configWorkSpaceId: o.configWorkspace.id
			}));

			teamPicNameKeyword.current = response.teamPic?.personalInfo?.fullName;
			siteNameKeyword.current = response.customerDetail?.customerContractSite?.name;
			customerKeyword.current = response.customerDetail?.customerPicContact.picName;
			contractNameKeyword.current = response.customerDetail?.customerContract?.name;
			registeredNameKeyword.current = response.customerDetail?.customer.registeredName;
			preparedByKeyword.current = response.signature?.preparedBy?.personalInfo?.fullName;	

			onHandleUpdateWIRAssets(wirAssets);

			onHandleGetList(workInspectionReportId);

			setData(response.quotation);

			props.onHandleSetWIRInfo({ referenceNo: response.referenceNo, employeeCustomerId: response.customerDetail?.customer.id, status: response.status, lastModifiedBy: response.lastModifiedBy, lastModifiedDate: dayjs(response.lastModifiedDate).format(DATE_TIME.LAST_UPDATED_BY_DATE) });
		}
	}, [memoSetFormValues, onHandleGetList, onHandleUpdateWIRAssets, props]);

	//prettier-ignore
	const onHandleAssets = useCallback(async (workInspectionId, wirAssets) => {
		let response = null;
		let processedWIRAssets = [];

		if (isContract) {
			processedWIRAssets = wirAssets?.map((o) => {
				const { customerAssetName, assetName, workspace, assetType, ...remainder } = o;

				return remainder;
			});
		} else {
			processedWIRAssets = wirAssets?.map((o) => {
				const { customerContractAssetId, ...remainder } = o;

				return remainder;
			});
		}

		try {
			const payload = {
				workInspectionId: workInspectionId,
				customerContractSiteId: formik.values.siteId,
				assets: processedWIRAssets
			};

			await api.post.workInspectionReport.assets(payload);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			if (isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Assets were added successfully!" }));
			}

			if (!isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Assets were updated successfully!" }));

				onHandleGetDetails(id);
			}
		}
	}, [dispatch, formik.values.siteId, id, isContract, isCreate, onHandleGetDetails]);

	// prettier-ignore
	const onHandleRemoveAsset = useCallback((value) => {
		let nextWIRAssets = structuredClone(formik.values.wirAssets);

		if (isCreate) {
			nextWIRAssets = isContract ? nextWIRAssets.filter((o) => o.id !== value) : nextWIRAssets.filter((o, i) => i !== value);

			formik.setFieldValue("wirAssets", nextWIRAssets);
		}

		if (!isCreate) {
			nextWIRAssets = nextWIRAssets.map((o) => {
				if (o.id === value) {
					o.deleted = true;
				}

				return o;
			});

			formik.setFieldValue("wirAssets", nextWIRAssets);

			onHandleAssets(id, nextWIRAssets);
		}
	}, [formik, id, isContract, isCreate, onHandleAssets]);

	//prettier-ignore
	const onHandleUpdateTeamMembersLocal = useCallback((teamMembers) => {
		formik.setFieldValue("teamMembers", teamMembers);
	}, [formik]);

	//prettier-ignore
	const onHandleUpdateTeamMembers = useCallback(async (teamMembers, workInspectionId) => {
		let response = null;
		
		try {
			const employeeIds = teamMembers.map((o) => o.id);

			const payload = {
				workInspectionId: workInspectionId,
				employeeIds: employeeIds
			};

			response = await api.post.workInspectionReport.addTeamMembers(payload);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			if (isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Work Inspection Report was added successfully!" }));
			}

			if (!isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Work Inspection Report was updated successfully!" }));
			}
		}
	}, [dispatch, isCreate]);

	//prettier-ignore
	const onHandleSignatures = useCallback(async (image, workInspectionId, signatureTypes) => {
		let response = null;

		if (!signatureTypes || !image) return;

		try {
			const formData = new FormData();

			formData.append("file", image);
			formData.append("wirId", workInspectionId);
			formData.append("employeeCustomerId", profile.id);
			formData.append("signatureType", signatureTypes);
			formData.append("reasonDescription", formik.values.reasonForReschedule || formik.values.reasonForCancellation);

			await api.post.workInspectionReport.signatureUpload(formData);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			if (isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Signature was added successfully!" }));
			}
			
			if (!isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Signature was updated successfully!" }));

				onHandleGetDetails(id);
			}
		}
	}, [dispatch, formik.values.reasonForCancellation, formik.values.reasonForReschedule, isCreate, profile.id, onHandleGetDetails, id]);

	// prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			const payload = {
				contractType: values.type,
				scheduledDate: combineDateTime(values.scheduledDate, values.scheduledTime),
				targetCompletionDate: combineDateTime(values.targetCompletionDate, values.targetCompletionTime),
				teamPicId: values.teamPIC,
				title: values.reportTitle,
				inspectionProcedure: values.inspectionDetails,
				planningAction: values.planningAction,
				customerDetail: {
					customerId: values.customerId,
					customerPicContactId: values.customerPicId,
					customerContractId: values.contractId,
					customerContractSiteName: values.siteName,
					customerContractSiteId: values.siteId,
					configBuildingTypeId: values.buildingType,
					address_1: values.addressLine1,
					address_2: values.addressLine2,
					state: values.state,
					city: values.city,
					postcode: values.postcode
				}
			};

			if (isCreate) {
				response = await api.post.workInspectionReport.createWorkInspectionReport(payload);
			}

			if (!isCreate) {
				payload.id = values.id;
				payload.referenceNo = values.referenceNo;
				payload.status = values.status;

				response = await api.post.workInspectionReport.updateWorkInspectionReport(payload);
			}
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			if (isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Work Inspection Report was added successfully!" }));
			}

			if (!isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Work Inspection Report was updated successfully!" }));
			}

			onHandleSignatures(values.preparedBy?.signatureFile, response.id, SIGNATURE_TYPE.PREPARED_BY);

			onHandleAssets(response.id, formik.values.wirAssets);

			if (formik.values.teamMembers?.length && isCreate) {
				onHandleUpdateTeamMembers(formik.values.teamMembers, response.id);
			}

			navigate(pathnames.workInspectionListing.workInspectionListing);
		}
	}, [isCreate, formik, onHandleSignatures, onHandleAssets, navigate, dispatch, onHandleUpdateTeamMembers]);

	const getRegionLoadOptions = useCallback(() => getRegionListing({ state: formik.values.state }), [formik.values.state]);
	const getPostcodeLoadOptions = useCallback(() => getPostcodeListing(`${formik.values.state}/${formik.values.city}`), [formik.values.state, formik.values.city]);

	//prettier-ignore
	const onHandleSetSignature = useCallback((name, value, signatureType) => {
		let signatureName = `${name.split(".")[0]}.signatureFile`;

		formik.setFieldValue(value, name);
		formik.setFieldValue(signatureName, value);

		if (!isCreate) onHandleSignatures(value, id, signatureType);
	}, [formik, id, isCreate, onHandleSignatures]);

	//prettier-ignore
	const onHandleChangeType = useCallback(async (value) => {
		formik.setFieldValue("type", value);
		formik.setFieldValue("siteName", "");
		formik.setFieldValue("wirAssets", []);
	}, [formik]);

	//prettier-ignore
	const onHandleSelectCustomerPic = useCallback(async (event) => {
		const value = event.target.value;

		let customerPic = {};

		try {
			const customerPicListing = await getCustomerPicListing({ id: formik.values.customerId, keyword: value });

			customerPic = customerPicListing.content[0];
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (customerPic) {
			customerKeyword.current = customerPic.label;

			formik.setFieldValue("customerPic", customerPic.label);
			formik.setFieldValue("customerPicId", customerPic?.id);
			formik.setFieldValue("email", customerPic?.email);
			formik.setFieldValue("prefixNo", customerPic?.mobileNoPrefix);
			formik.setFieldValue("mobileNo", customerPic?.mobileNo);
		}
	}, [formik]);

	//prettier-ignore
	const onHandleSelectSiteName = useCallback(async (event) => {
		const value = event.target.value;

		let response = null;

		try {
			response = await api.get.customerSiteMaintenance.site(value);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			siteNameKeyword.current = response.name;

			formik.setFieldValue("siteId", value);
			formik.setFieldValue("buildingType", response.configBuildingTypeId);
			formik.setFieldValue("addressLine1", response.address1);
			formik.setFieldValue("addressLine2", response.address2);
			formik.setFieldValue("state", response.state);
			formik.setFieldValue("city", response.region);
			formik.setFieldValue("postcode", response.postcode);
		}

		getContractSitesListing({ id: formik.values.contractId, keyword: siteNameKeyword.current })
	}, [formik]);

	const onHandleBack = useCallback(() => {
		navigate(pathnames.workInspectionListing.workInspectionListing);
	}, [navigate]);

	//prettier-ignore
	const onHandleChangeRegisteredName = useCallback(async (event) => {
		const value = event.target.value;

		let response = null;

		try {
			response = await api.get.customer.customer(value);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			registeredNameKeyword.current = response.registeredName;

			formik.setFieldValue("customerId", value);
		}

		getCustomerLookupListing({ keyword: registeredNameKeyword.current });
	}, [formik]);

	useImperativeHandle(ref, () => ({
		onHandleGetDetails: onHandleGetDetails
	}));

	useEffect(() => {
		if (isCreate || isPendingAcknowledge) onHandleGetEmployeeDetail(profile.id);

		if (!isCreate) onHandleGetDetails(id);
	}, [id, isCreate, isPendingAcknowledge, onHandleGetDetails, onHandleGetEmployeeDetail, profile.id]);

	useEffect(() => {
		return () => {
			if (isCreate || isPendingAcknowledge) cancelRequest(ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEE_HR);

			if (!isCreate) cancelRequest(ENDPOINT_PATH.WORK_INSPECTION_REPORT.REPORT);
		};
	}, [cancelRequest, isCreate, isPendingAcknowledge]);

	return (
		<div className="app-report-details">
			<div className="report-details">
				<form className="report-details__form" onSubmit={formik.handleSubmit}>
					<div className="report-details__container">
						<p className="report-details__label">General Details</p>

						{!isCreate && (
							<div className="report-details__row">
								<AppInput disabled type="text" name="referenceNo" label="Report ID" placeholder="" value={formik.values.referenceNo} touched={formik.touched.referenceNo} onChange={formik.handleChange} />

								<AppInput disabled type="text" name="status" label="Status" placeholder="Select..." value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />
							</div>
						)}

						<div className="report-details__row">
							<AppRadioInput required disabled={!isBeforeInProgress} label="Type" options={contractTypeOptions} value={formik.values.type} error={formik.errors.type} touched={formik.touched.type} onChange={(v) => onHandleChangeType(v)} />
						</div>
					</div>

					<div className="report-details__container">
						<p className="report-details__label">Customer Details</p>

						<div className="report-details__row">
							<AppSelectInput disabled={restricted || !isBeforeInProgress} required pagination name="customerId" label="Registered Name" placeholder="Select..." defaultSearchValue={registeredNameKeyword.current} loadOptions={getCustomerLookupListing} value={formik.values.customerId} error={formik.errors.customerId} touched={formik.touched.customerId} onChange={onHandleChangeRegisteredName} />

							<AppSelectInput disabled={restricted || !formik.values.customerId || !isBeforeInProgress} required pagination name="customerPic" label="Customer PIC" placeholder="Select..." defaultSearchValue={customerKeyword.current} loadOptions={(payload) => getCustomerPicListing({ id: formik.values.customerId, keyword: payload?.keyword, page: payload?.page })} value={formik.values.customerPic} error={formik.errors.customerPic} touched={formik.touched.customerPic} onChange={onHandleSelectCustomerPic} />
						</div>

						<div className="report-details__row">
							{isContract && <AppSelectInput disabled={restricted || !formik.values.customerPic || !isBeforeInProgress} pagination name="contractId" label="Contract ID" placeholder="Select..." defaultSearchValue={contractNameKeyword.current} loadOptions={(payload) => getContractByPicListing({ id: formik.values.customerPicId, keyword: payload?.keyword, page: payload?.page })} value={formik.values.contractId} error={formik.errors.contractId} touched={formik.touched.contractId} onChange={formik.handleChange} />}

							{isContract && <AppSelectInput disabled={restricted || !formik.values.contractId || !isBeforeInProgress} pagination name="siteId" label="Site Name" placeholder="Select..." defaultSearchValue={siteNameKeyword.current} loadOptions={(payload) => getContractSitesListing({ id: formik.values.contractId, keyword: payload?.keyword, page: payload?.page })} value={formik.values.siteId} error={formik.errors.siteId} touched={formik.touched.siteId} onChange={onHandleSelectSiteName} />}

							{!isContract && <AppInput disabled={restricted || !isBeforeInProgress} type="text" name="siteName" label="Site Name" placeholder="Enter Site Name" value={formik.values.siteName} touched={formik.touched.siteName} onChange={formik.handleChange} />}
						</div>

						<div className="report-details__row">
							<AppMobileInput disabled name="mobileNo" label="Mobile No" placeholder="Mobile No." value={formik.values.mobileNo} prefixNo={formik.values.prefixNo} error={formik.errors.mobileNo} touched={formik.touched.mobileNo} onChange={formik.handleChange} />

							<AppInput disabled type="text" name="email" label="Email" placeholder="Email" value={formik.values.email} error={formik.errors.email} touched={formik.touched.email} onChange={formik.handleChange} />
						</div>

						<p className="report-details__label">Site Location</p>

						<div className="report-details__row">
							<AppSelectInput disabled={restricted || isContract || !isBeforeInProgress} name="buildingType" label="Building Type" placeholder="Building Type" loadOptions={getBuildingTypeListing} value={formik.values.buildingType} touched={formik.touched.buildingType} onChange={formik.handleChange} />

							<AppInput required disabled={restricted || isContract || !isBeforeInProgress} type="text" name="addressLine1" label="Address Line 1" placeholder="Address Line 1" value={formik.values.addressLine1} touched={formik.touched.addressLine1} onChange={formik.handleChange} />
						</div>

						<div className="report-details__row">
							<AppInput disabled={restricted || isContract || !isBeforeInProgress} type="text" name="addressLine2" label="Address Line 2" placeholder="Address Line 2" value={formik.values.addressLine2} touched={formik.touched.addressLine2} onChange={formik.handleChange} />

							<AppSelectInput required disabled={restricted || isContract || !isBeforeInProgress} name="state" label="State" placeholder="State" loadOptions={getStateListing} value={formik.values.state} touched={formik.touched.state} onChange={formik.handleChange} />
						</div>

						<div className="report-details__row">
							<AppSelectInput required disabled={!formik.values.state || restricted || isContract || !isBeforeInProgress} name="city" label="City" placeholder="City" loadOptions={getRegionLoadOptions} value={formik.values.city} touched={formik.touched.city} onChange={formik.handleChange} />

							<AppSelectInput required disabled={!formik.values.city || restricted || isContract || !isBeforeInProgress} name="postcode" label="Postcode" placeholder="Postcode" loadOptions={getPostcodeLoadOptions} value={formik.values.postcode} touched={formik.touched.postcode} onChange={formik.handleChange} />
						</div>

						<AppReportDetailsAssetTable isContract={isContract} isVerified={isVerified} isBeforeInProgress={isBeforeInProgress} status={formik.values.status} siteId={formik.values.siteId} wirAssets={formik.values.wirAssets} onHandleUpdateWIRAssets={onHandleUpdateWIRAssets} onHandleGetDetails={onHandleGetDetails} onHandleRemoveAsset={onHandleRemoveAsset} />
					</div>

					<div className="report-details__container">
						<p className="report-details__label">Scheduling</p>

						<div className="report-details__row">
							<AppInputDate disabled={restricted || !isBeforeInProgress} required name="scheduledDate" label="Scheduled Date" placeholder="DD/MM/YYYY" value={formik.values.scheduledDate} error={formik.errors.scheduledDate} touched={formik.touched.scheduledDate} onChange={formik.setFieldValue} />

							<AppInputDate disabled={restricted || !isBeforeInProgress} name="targetCompletionDate" label="Target Completion Date" placeholder="DD/MM/YYYY" value={formik.values.targetCompletionDate} error={formik.errors.targetCompletionDate} touched={formik.touched.targetCompletionDate} onChange={formik.setFieldValue} />
						</div>

						<div className="report-details__row">
							<AppInputTime disabled={restricted || !isBeforeInProgress} required name="scheduledTime" label="Scheduled Time" placeholder="HH:MM" value={formik.values.scheduledTime} error={formik.errors.scheduledTime} touched={formik.touched.scheduledTime} onChange={formik.setFieldValue} />

							<AppInputTime disabled={restricted || !isBeforeInProgress} name="targetCompletionTime" label="Target Completion Time" placeholder="HH:MM" value={formik.values.targetCompletionTime} error={formik.errors.targetCompletionTime} touched={formik.touched.targetCompletionTime} onChange={formik.setFieldValue} />
						</div>

						<div className="report-details__row">
							<AppSelectInput disabled={restricted || !isBeforeInProgress} pagination name="teamPIC" label="Team PIC" placeholder="Select..." loadOptions={getEmployeeMaintenanceListing} value={formik.values.teamPIC} defaultSearchValue={teamPicNameKeyword.current} touched={formik.touched.teamPIC} onChange={formik.handleChange} />
						</div>

						<AppReportDetailsTeamMemberTable isBeforeInProgress={isBeforeInProgress} status={formik.values.status} teamMembersData={formik.values.teamMembers} onHandleUpdateTeamMembersLocal={onHandleUpdateTeamMembersLocal} onHandleUpdateTeamMembers={onHandleUpdateTeamMembers} />
					</div>

					<div className="report-details__container">
						<p className="report-details__label">Duration Details</p>

						<div className="report-details__row">
							<AppInputDate disabled name="startDate" label="Start Date" placeholder="DD/MM/YYYY" value={formik.values.startDate} touched={formik.touched.startDate} onChange={formik.setFieldValue} />

							<AppInputDate disabled name="endDate" label="End Date" placeholder="DD/MM/YYYY" value={formik.values.endDate} touched={formik.touched.endDate} onChange={formik.setFieldValue} />
						</div>

						<div className="report-details__row">
							<AppInputTime disabled name="startTime" label="Start Time" placeholder="HH:MM" value={formik.values.startTime} touched={formik.touched.startTime} onChange={formik.setFieldValue} />

							<AppInputTime disabled name="endTime" label="End Time" placeholder="HH:MM" value={formik.values.endTime} touched={formik.touched.endTime} onChange={formik.setFieldValue} />
						</div>

						<div className="report-details__row">
							<AppInput disabled type="text" name="duration" label="Duration" placeholder="Duration" value={formik.values.duration} touched={formik.touched.duration} onChange={formik.handleChange} />
						</div>

						<p className="report-details__label">Quotations</p>

						<AppReportDetailsQuotationsTable data={data} />
					</div>

					<div className="report-details__container">
						<p className="report-details__label">Content</p>

						<AppInput disabled={restricted || !isBeforeInProgress} required type="text" name="reportTitle" label="Report Title" placeholder="Enter Report Title" value={formik.values.reportTitle} error={formik.errors.reportTitle} touched={formik.touched.reportTitle} onChange={formik.handleChange} />

						<div className="report-details__row">
							<AppInput disabled={restricted || !isBeforeInProgress} multiline type="textarea" name="inspectionDetails" label="Inspection Procedure/Details" placeholder="Enter Inspection Procedure/Details" value={formik.values.inspectionDetails} error={formik.errors.inspectionDetails} touched={formik.touched.inspectionDetails} onChange={formik.handleChange} />

							<AppInput disabled={restricted || !isBeforeInProgress} multiline type="textarea" name="planningAction" label="Planning of Corrective Action" placeholder="Enter Planning of Corrective Action" value={formik.values.planningAction} error={formik.errors.planningAction} touched={formik.touched.planningAction} onChange={formik.handleChange} />
						</div>
					</div>

					<div className="report-details__container">
						<div className="report-details__row report-details__row--signature">
							<div className="report-details__column report-details__column--signature">
								<AppInput disabled name="preparedBy.name" label="Prepared By" placeholder="Search by User ID or Name" value={formik.values.preparedBy?.name} touched={formik.touched.preparedBy?.name} onChange={formik.handleChange} />

								<AppInputDragAndDrop disabled={restricted} name="preparedBy.signature" accept="image/png, image/jpeg, image/jpg" value={formik.values.preparedBy?.signature} error={formik.errors.preparedBy?.signature} touched={formik.touched.preparedBy?.signature} onChange={(name, file) => onHandleSetSignature(name, file, SIGNATURE_TYPE.PREPARED_BY)} />
							</div>

							<div className="report-details__column report-details__column--signature">
								<AppInput disabled type="text" name="completedBy.name" label="Completed By" placeholder="Completed By" value={formik.values.completedBy?.name} error={formik.errors.completedBy?.name} touched={formik.touched.completedBy?.touched} onChange={formik.handleChange} />

								<AppInputDragAndDrop disabled name="completedBy.signature" accept="image/png, image/jpeg, image/jpg" value={formik.values.completedBy?.signature} error={formik.errors.completedBy?.signature} touched={formik.touched.completedBy?.touched} onChange={(name, file) => onHandleSetSignature(name, file, SIGNATURE_TYPE.COMPLETED_BY)} />
							</div>
						</div>

						<div className="report-details__row report-details__row--signature">
							<div className="report-details__column report-details__column--signature">
								<AppInput disabled name="acknowledgedBy.name" label="Acknowledged By" placeholder="Search by User ID or Name" value={formik.values.acknowledgedBy?.name} error={formik.errors.acknowledgedBy?.name} touched={formik.touched.acknowledgedBy?.name} onChange={formik.handleChange} />

								<AppInputDragAndDrop disabled={!isPendingAcknowledge} name="acknowledgedBy.signature" accept="image/png, image/jpeg, image/jpg" value={formik.values.acknowledgedBy?.signature} error={formik.errors.acknowledgedBy?.signature} touched={formik.touched.acknowledgedBy?.touched} onChange={(name, file) => onHandleSetSignature(name, file, SIGNATURE_TYPE.ACKNOWLEDGED_BY)} />
							</div>

							<div className="report-details__column report-details__column--signature">
								<AppInput disabled type="text" name="verifiedBy.name" label="Verified By" placeholder="Verified By" value={formik.values.verifiedBy?.name} error={formik.errors.verifiedBy?.name} touched={formik.touched.verifiedBy?.name} onChange={formik.handleChange} />

								<AppInputDragAndDrop disabled name="verifiedBy.signature" accept="image/png, image/jpeg, image/jpg" value={formik.values.verifiedBy?.signature} error={formik.errors.verifiedBy?.signature} touched={formik.touched.verifiedBy?.signature} onChange={(name, file) => onHandleSetSignature(name, file, SIGNATURE_TYPE.VERIFIED_BY)} />
							</div>
						</div>

						<div className="report-details__row report-details__row--signature">
							{isCancelled && (
								<Fragment>
									<div className="report-details__column report-details__column--signature">
										<AppInput disabled type="text" name="cancelledBy.name" label="Cancelled By" placeholder="Cancelled By" value={formik.values.cancelledBy?.name} error={formik.errors.cancelledBy?.name} touched={formik.touched.cancelledBy?.name} onChange={formik.handleChange} />

										<AppInputDragAndDrop disabled name="cancelledBy.signature" accept="image/png, image/jpeg, image/jpg" value={formik.values.cancelledBy?.signature} error={formik.errors.cancelledBy?.signature} touched={formik.touched.cancelledBy?.signature} onChange={(name, file) => onHandleSetSignature(name, file, SIGNATURE_TYPE.PENDING_RESCHEDULED_BY)} />
									</div>

									<div className="report-details__column report-details__column--signature">
										<AppInput disabled multiline type="textarea" name="reasonForCancellation" label="Reason for Cancellation" placeholder="Reason for Cancellation" value={formik.values.reasonForCancellation} error={formik.errors.reasonForCancellation} touched={formik.touched.reasonForCancellation} onChange={formik.handleChange} />
									</div>
								</Fragment>
							)}

							{isPendingRescheduled && (
								<Fragment>
									<div className="report-details__column report-details__column--signature">
										<AppInput disabled type="text" name="pendingRescheduledBy.name" label="Pending Rescheduled By" placeholder="Pending Rescheduled By" value={formik.values.pendingRescheduledBy?.name} error={formik.errors.pendingRescheduledBy?.name} touched={formik.touched.pendingRescheduledBy?.name} onChange={formik.handleChange} />

										<AppInputDragAndDrop disabled name="pendingRescheduledBy.signature" accept="image/png, image/jpeg, image/jpg" value={formik.values.pendingRescheduledBy?.signature} error={formik.errors.pendingRescheduledBy?.signature} touched={formik.touched.pendingRescheduledBy?.signature} onChange={(name, file) => onHandleSetSignature(name, file, SIGNATURE_TYPE.PENDING_RESCHEDULED_BY)} />
									</div>

									<div className="report-details__column report-details__column--signature">
										<AppInput disabled multiline type="textarea" name="reasonForReschedule" label="Reason for Reschedule" placeholder="Reason for Reschedule" value={formik.values.reasonForReschedule} error={formik.errors.reasonForReschedule} touched={formik.touched.reasonForReschedule} onChange={formik.handleChange} />
									</div>
								</Fragment>
							)}

							{isRescheduled && (
								<Fragment>
									<div className="report-details__column report-details__column--signature">
										<AppSelectInput disabled name="rescheduledBy.employeeId" label="Rescheduled By" placeholder="Search by User ID or Name" loadOptions={getEmployeeMaintenanceListing} value={formik.values.rescheduledBy?.employeeId} error={formik.errors.rescheduledBy?.employeeId} touched={formik.touched.rescheduledBy?.employeeId} onChange={formik.handleChange} />

										<AppInputDragAndDrop disabled name="rescheduledBy.signature" accept="image/png, image/jpeg, image/jpg" value={formik.values.rescheduledBy?.signature} error={formik.errors.rescheduledBy?.signature} touched={formik.touched.rescheduledBy?.signature} onChange={(name, file) => onHandleSetSignature(name, file, SIGNATURE_TYPE.RESCHEDULED_BY)} />
									</div>

									<div className="report-details__column report-details__column--signature">
										<AppInput disabled multiline type="textarea" name="reasonForReschedule" label="Reason for Reschedule" placeholder="Reason for Reschedule" value={formik.values.reasonForReschedule} error={formik.errors.reasonForReschedule} touched={formik.touched.reasonForReschedule} onChange={formik.handleChange} />
									</div>
								</Fragment>
							)}
						</div>
					</div>

					<div className="report-details__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleBack} />

						<AppButton type="submit" label={submitLabel} disabled={formik.isSubmitting || !isBeforeInProgress} />
					</div>
				</form>
			</div>
		</div>
	);
};

export default memo(forwardRef(AppReportDetails));
