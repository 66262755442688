import React, { forwardRef, memo, useCallback, useMemo } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import api from "services/api";
import getFrequencyListing from "services/get-frequency-listing";
import getWorkSpaceListing from "services/get-workspace-listing";
import getAssetTypeListing from "services/get-asset-type-listing";

import useBreadcrumb from "hooks/use-breadcrumb";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import ROLES from "constants/roles";
import ERRORS from "constants/errors";
import STATUS from "constants/status";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppInputDate from "components/app-input-date";
import AppSelectInput from "components/app-select-input";

const AppCustomerEditAssetDetails = (props, ref) => {
	const profile = useSelector((state) => state.profile);
	const { id } = useParams();
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const accessible = useMemo(() => profile?.permissions?.[ROLES.CUSTOMER_CONTRACT], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const assetDetails = useMemo(() => props.assetDetails, [props.assetDetails]);

	const initialValues = useMemo(() => {
		let values = {
			id: "",
			referenceNo: "",
			customerContractSiteId: "",
			createdDate: "",
			status: "",
			customerAssetName: "",
			description: "",
			assetName: "",
			assetTypeId: "",
			remarks: "",
			frequency: "",
			lastService: "",
			nextService: "",
			workspaceId: "",
			serviceRemarks: "",
			scheduledDate: ""
		};

		if (assetDetails) {
			values = {
				...assetDetails,
				referenceNo: assetDetails?.referenceNo || "",
				status: assetDetails?.assetType.status || "",
				remarks: assetDetails?.remark,
				assetTypeId: assetDetails.assetType.id,
				workspaceId: assetDetails.workspace.id,
				createdDate: dayjs(assetDetails.createdDate),
				scheduledDate: dayjs(assetDetails?.firstService),
				lastService: dayjs(assetDetails?.lastService),
				nextService: dayjs(assetDetails?.scheduledDate)
			};
		}

		return values;
	}, [assetDetails]);

	const formik = useFormik({
		enableReinitialize: true,
		initialValues: initialValues,
		validationSchema: yup.object({
			customerContractSiteId: yup.string().required(ERRORS.REQUIRED),
			customerAssetName: yup.string().required(ERRORS.REQUIRED),
			assetName: yup.string().required(ERRORS.REQUIRED),
			assetTypeId: yup.string().required(ERRORS.REQUIRED),
			frequency: yup.string().required(ERRORS.REQUIRED),
			lastService: yup.date().required(ERRORS.REQUIRED).typeError(ERRORS.REQUIRED),
			nextService: yup.date().required(ERRORS.REQUIRED).typeError(ERRORS.REQUIRED),
			workspaceId: yup.string().required(ERRORS.REQUIRED),
			scheduledDate: yup.date().required(ERRORS.REQUIRED).typeError(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	const breadCrumb = useMemo(() => {
		return [
			{ label: "Customer Listing ", path: pathnames.customer.customers },
			{ label: "Edit Site ", path: pathnames.customer.customerCreateEditSite + formik.values.customerContractSiteId },
			{ label: "Edit Asset", path: pathnames.customer.customerEditAsset + id }
		];
	}, [id, formik.values.customerContractSiteId]);

	useBreadcrumb({ breadCrumb });

	//prettier-ignore
	const statusOptions = useMemo(() => [
		{ label: "Active", value: STATUS.ACTIVE },
		{ label: "Inactive", value: STATUS.INACTIVE },
		{ label: "Missing", value: STATUS.MISSING },
		{ label: "Non-Accessible", value: STATUS.NON_ACCESSIBLE }
	], []);

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			const payload = {
				id: id,
				customerContractSiteId: values.customerContractSiteId,
				customerAssetName: values.customerAssetName,
				assetName: values.assetName,
				assetTypeId: values.assetTypeId,
				frequency: values.frequency,
				firstService: values.scheduledDate,
				lastService: values.lastService,
				workspaceId: values.workspaceId,
				description: values.description,
				remarks: values.remarks,
				status: values.status,
			};

			await api.post.assetMaintenance.updateAsset({ "asset-id": id, ...payload });

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Asset was updated successfully!" }));

			props.onHandleGetDetails(id);
		}
	}, [dispatch, formik, id, props]);

	const onHandleBack = useCallback(() => {
		navigate(pathnames.customer.customerCreateEditSite + formik.values.customerContractSiteId);
	}, [navigate, formik]);

	return (
		<div className="app-customer-edit-asset-details">
			<div className="customer-edit-asset-details">
				<form className="customer-edit-asset-details__form" onSubmit={formik.handleSubmit}>
					<div className="customer-edit-asset-details__container">
						<p className="customer-edit-asset-details__label">Asset Details</p>

						<div className="customer-edit-asset-details__row">
							<AppInput disabled type="text" name="referenceNo" label="Asset ID" placeholder="Asset ID" value={formik.values.referenceNo} error={formik.errors.referenceNo} touched={formik.touched.referenceNo} onChange={formik.handleChange} />

							<AppInputDate disabled name="createdDate" label="Date Added" value={formik.values.createdDate} />
						</div>

						<div className="customer-edit-asset-details__row">
							<AppInput required type="text" name="customerAssetName" disabled={restricted} label="Customer Asset Name" placeholder="Customer Asset Name" value={formik.values.customerAssetName} error={formik.errors.customerAssetName} touched={formik.touched.customerAssetName} onChange={formik.handleChange} />

							<AppSelectInput required searchable={false} type="text" name="status" label="Status" placeholder="Select..." options={statusOptions} value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />
						</div>

						<div className="customer-edit-asset-details__row">
							<div className="customer-edit-asset-details__column">
								<AppInput required type="text" name="assetName" disabled={restricted} label="Asset Name" placeholder="Enter Asset Name" value={formik.values.assetName} error={formik.errors.assetName} touched={formik.touched.assetName} onChange={formik.handleChange} />

								<AppSelectInput required pagination name="assetTypeId" disabled={restricted} label="Asset Type" placeholder="Select..." loadOptions={getAssetTypeListing} value={formik.values.assetTypeId} error={formik.errors.assetTypeId} touched={formik.touched.assetTypeId} onChange={formik.handleChange} />
							</div>
							<AppInput multiline type="textarea" disabled={restricted} label="Description" maxLength={255} name="description" placeholder="Enter Description" value={formik.values.description} error={formik.errors.description} touched={formik.touched.description} onChange={formik.handleChange} />
						</div>

						<div className="customer-edit-asset-details__row">
							<div className="customer-edit-asset-details__column">
								<AppSelectInput required name="frequency" disabled={restricted} label="Frequency" placeholder="Select..." loadOptions={getFrequencyListing} value={formik.values.frequency} error={formik.errors.frequency} touched={formik.touched.frequency} onChange={formik.handleChange} />

								<AppSelectInput required pagination name="workspaceId" disabled={restricted} label="Workspace" placeholder="Select..." loadOptions={getWorkSpaceListing} value={formik.values.workspaceId} error={formik.errors.workspaceId} touched={formik.touched.workspaceId} onChange={formik.handleChange} />
							</div>
							<AppInput multiline type="textarea" disabled={restricted} label="Remarks" maxLength={255} name="remarks" placeholder="Enter Remarks" value={formik.values.remarks} error={formik.errors.remarks} touched={formik.touched.remarks} onChange={formik.handleChange} />
						</div>

						<p className="customer-edit-asset-details__label">Work Order Configuration</p>

						<div className="customer-edit-asset-details__row">
							<AppInputDate required name="scheduledDate" disabled={restricted} label="Scheduled Date" value={formik.values.scheduledDate} error={formik.errors.scheduledDate} touched={formik.touched.scheduledDate} onChange={formik.setFieldValue} placeholder="DD/MM/YYYY" />

							<AppInputDate required name="lastService" disabled label="Last Service" value={formik.values.lastService} error={formik.errors.lastService} touched={formik.touched.lastService} onChange={formik.setFieldValue} placeholder="DD/MM/YYYY" />
						</div>

						<div className="customer-edit-asset-details__row">
							<AppInputDate required name="nextService" disabled label="Next Service" value={formik.values.nextService} error={formik.errors.nextService} touched={formik.touched.nextService} onChange={formik.setFieldValue} placeholder="DD/MM/YYYY" />
						</div>
					</div>

					<div className="customer-edit-asset-details__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleBack} />

						<AppButton type="submit" label="Update" disabled={formik.isSubmitting || restricted} />
					</div>
				</form>
			</div>
		</div>
	);
};

export default memo(forwardRef(AppCustomerEditAssetDetails));
