import React, { forwardRef, memo, useCallback, useContext, useEffect, useImperativeHandle, useState } from "react";

import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { AxiosContext } from "contexts/with-interceptor-provider";

import api from "services/api";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import ENDPOINT_PATH from "constants/end-point-path";

import AppButton from "components/app-button";

export const AppEmployeeDirectoryViewContactModal = (props, ref) => {
	const [id, setId] = useState(null);
	const [data, setData] = useState(null);
	const [visible, setVisible] = useState(false);
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;

	const onHandleShow = useCallback((values) => {
		setId(values);

		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	const onHandleGetDetails = useCallback(async (uniqueId) => {
		let response = null;

		try {
			response = await api.get.humanResource.employee(uniqueId);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			setData({
				fullName: response.personalInfo.fullName,
				email: response.employeeDetail.workEmail,
				firstMobileNumber: response.personalInfoContact.primaryMobileNoPrefix + " " + response.personalInfoContact.primaryMobileNo,
				secondMobileNumber: response.personalInfoContact.secondaryMobileNo ? response.personalInfoContact.secondaryMobileNoPrefix + " " + response.personalInfoContact.secondaryMobileNo : "-",
				emergencyContact: response?.emergencyContact
			});
		}
	}, []);

	useEffect(() => {
		if (visible) onHandleGetDetails(id);
	}, [id, onHandleGetDetails, visible]);

	useEffect(() => {
		return () => {
			if (visible) cancelRequest(ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEE);
		};
	}, [cancelRequest, visible]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-employee-directory-view-contact-modal" }} open={visible}>
			<div className="employee-directory-view-contact-modal">
				<h1 className="employee-directory-view-contact-modal__title">Employee Contact</h1>

				<div className="employee-directory-view-contact-modal__container">
					<h2 className="employee-directory-view-contact-modal__header">{data?.fullName}</h2>

					<div className="employee-directory-view-contact-modal__box">
						<div className="employee-directory-view-contact-modal__details">
							<p className="employee-directory-view-contact-modal__text employee-directory-view-contact-modal__text--bold">Email</p>

							<p className="employee-directory-view-contact-modal__text">{data?.email}</p>
						</div>

						<div className="employee-directory-view-contact-modal__details">
							<p className="employee-directory-view-contact-modal__text employee-directory-view-contact-modal__text--bold">Primary Mobile Number</p>

							<p className="employee-directory-view-contact-modal__text">{data?.firstMobileNumber}</p>
						</div>

						<div className="employee-directory-view-contact-modal__details">
							<p className="employee-directory-view-contact-modal__text employee-directory-view-contact-modal__text--bold">Secondary Mobile Number</p>

							<p className="employee-directory-view-contact-modal__text">{data?.secondMobileNumber}</p>
						</div>
					</div>
				</div>

				<div className="employee-directory-view-contact-modal__container">
					<h2 className="employee-directory-view-contact-modal__header">Emergency Contact</h2>

					{data?.emergencyContact.map((o, i) => (
						<div className="employee-directory-view-contact-modal__box" key={i}>
							<div className="employee-directory-view-contact-modal__details">
								<p className="employee-directory-view-contact-modal__text employee-directory-view-contact-modal__text--bold">Full Name</p>

								<p className="employee-directory-view-contact-modal__text">{o?.fullName}</p>
							</div>

							<div className="employee-directory-view-contact-modal__details">
								<p className="employee-directory-view-contact-modal__text employee-directory-view-contact-modal__text--bold">Relationship</p>

								<p className="employee-directory-view-contact-modal__text">{o?.relationship}</p>
							</div>

							<div className="employee-directory-view-contact-modal__details">
								<p className="employee-directory-view-contact-modal__text employee-directory-view-contact-modal__text--bold">Mobile Number</p>

								<p className="employee-directory-view-contact-modal__text">{o?.mobileNo ? o?.mobileNoPrefix + " " + o?.mobileNo : "-"}</p>
							</div>
						</div>
					))}
				</div>

				<AppButton className="employee-directory-view-contact-modal__button" type="button" label="Done" onClick={onHandleDismiss} />
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppEmployeeDirectoryViewContactModal));

AppEmployeeDirectoryViewContactModal.propTypes = {
	ref: PropTypes.object.isRequired,
	onConfirm: PropTypes.func.isRequired
};
