import React, { forwardRef, memo, useCallback, useImperativeHandle, useState } from "react";

import PropTypes from "prop-types";
import { Modal } from "@mui/material";

import AppButton from "components/app-button";

export const AppSalesOrderConfirmationModal = (props, ref) => {
	const [visible, setVisible] = useState(false);
	const [data, setData] = useState([]);

	const onHandleConfirm = useCallback(() => {
		props.onConfirm();

		setVisible(false);
	}, [props]);

	const onHandleShow = useCallback((referenceNo) => {
		setData(referenceNo);

		setVisible(true);
	}, []);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-sales-order-confirmation-modal" }} open={visible}>
			<div className="sales-order-confirmation-modal">
				<h1 className="sales-order-confirmation-modal__title">Cancel Work Inspection Report?</h1>

				<p className="sales-order-confirmation-modal__description">
					After clicking confirm, the status for Sales Order <span className="sales-order-confirmation-modal__sales-name">{data}</span> will be set to <span className="sales-order-confirmation-modal__description--drop-off">Drop Off</span>.
				</p>

				<div className="sales-order-confirmation-modal__button-container">
					<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

					<AppButton type="button" label="Confirm" onClick={onHandleConfirm} />
				</div>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppSalesOrderConfirmationModal));

AppSalesOrderConfirmationModal.propTypes = {
	ref: PropTypes.object
};
