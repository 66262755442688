import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";

import { useSelector } from "react-redux";
import { AxiosContext } from "contexts/with-interceptor-provider";

import api from "services/api";

import sanitizeObject from "common/sanitize-object";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import ENDPOINT_PATH from "constants/end-point-path";

import AppTabs from "components/app-tabs";
import AppEmployeeDirectoryTable from "components/pages/human-resources/app-employee-directory-table";
import AppCorrectiveAttendanceHistoryTable from "components/pages/human-resources/app-corrective-attendance-history-table";
import AppPendingCorrectiveAttendanceTable from "components/pages/human-resources/app-pending-corrective-attendance-table";

import personIcon from "assets/images/person-icon.svg";
import personActiveIcon from "assets/images/person-active-icon.svg";
import hourglassTickIcon from "assets/images/hourglass-tick-icon.svg";
import hourglassTickActiveIcon from "assets/images/hourglass-tick-active-icon.svg";
import hourglassIcon from "assets/images/pages/human-resources/hourglass-icon.svg";
import hourglassActiveIcon from "assets/images/pages/human-resources/hourglass-active-icon.svg";

const PageAttendanceManagement = () => {
	const profile = useSelector((state) => state.profile);
	const [isHR, setIsHR] = useState();
	const [employeeId, setEmployeeId] = useState();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;

	const onHandleGetEmployeeDetail = useCallback(async (uniqueId) => {
		let response = null;

		try {
			const params = sanitizeObject({ "user-id": uniqueId });

			response = await api.get.humanResource.employeeHR(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			setIsHR(!response.supervisor && !response.reportingManager);

			setEmployeeId(response.id);
		}
	}, []);

	const tabs = useMemo(() => {
		const data = [
			{ label: "Employee Directory", icon: personIcon, activeIcon: personActiveIcon, name: "EMPLOYEE_DIRECTORY", component: <AppEmployeeDirectoryTable />, accessible: true },
			{ label: "Corrective Attendance History", icon: hourglassIcon, activeIcon: hourglassActiveIcon, name: "CORRECTIVE_ATTENDANCE_HISTORY", component: <AppCorrectiveAttendanceHistoryTable />, accessible: true },
			{ label: "Pending Corrective Attendance", icon: hourglassTickIcon, activeIcon: hourglassTickActiveIcon, name: "PENDING_CORRECTIVE_ATTENDANCE", component: <AppPendingCorrectiveAttendanceTable employeeId={employeeId} isHR={isHR} />, accessible: true }
		];

		return data;
	}, [employeeId, isHR]);

	useEffect(() => {
		onHandleGetEmployeeDetail(profile.id);
	}, [onHandleGetEmployeeDetail, profile.id]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEE_HR);
		};
	}, [cancelRequest]);

	return (
		<div className="page-attendance-management">
			<div className="attendance-management">
				<h1 className="attendance-management__title">Attendance Management</h1>

				<div className="attendance-management__body">
					<AppTabs tabs={tabs} />
				</div>
			</div>
		</div>
	);
};

export default PageAttendanceManagement;
