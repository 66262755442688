import api from "services/api";

import serveRequestErrors from "common/serve-request-errors";

const getSparePartListing = async (payload) => {
	let response = null;
	let errorMessage = null;

	try {
		const params = { size: 10, page: payload?.page || 0, status: "IN_USE", keyword: payload?.keyword || "" };
		const res = await api.get.sparePart.spareParts(params);
		const content = res.content.map((item) => ({ value: item.id, label: item.name, sparePartCategoryId: item?.sparePartCategoryId, sparePartSubCategoryId: item?.sparePartSubCategoryId, doc: item?.doc }));

		response = { ...res, content };
	} catch (error) {
		errorMessage = serveRequestErrors(error);
	}

	if (response) return response;

	throw errorMessage;
};

export default getSparePartListing;
