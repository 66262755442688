import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import api from "services/api";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import ERRORS from "constants/errors";
import DATE_TIME from "constants/date-time";
import ENDPOINT_PATH from "constants/end-point-path";

import AppInputTime from "components/app-input-time";
import AppCalendarSchedule from "components/app-calendar-schedule/app-calendar-schedule";

const AppScheduledAttendanceEmployeeCalendar = () => {
	const { id } = useParams();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const [data, setData] = useState([]);
	const [editingStatus, setEditingStatus] = useState(false);
	const calendarRef = useRef(null);

	//prettier-ignore
	const formik = useFormik({
		initialValues: {
			clockIn: [],
			clockOut: []
		},
		validationSchema: yup.object({
			clockIn: yup.array().of(
				yup.mixed().nullable().test("required-when-clockOut", ERRORS.REQUIRED, function (value, context) {
					const path = context.path.split("[");
					const index = path[1]?.split("]")[0];
					const endTimeArray = context.options.context?.clockOut;
					const endTimeValue = endTimeArray ? endTimeArray[index] : "";

					if (!endTimeValue && !value) return true;

					return !!value;
				})
			),
			clockOut: yup.array().of(
				yup.mixed().nullable().test("required-when-clockIn", ERRORS.REQUIRED, function (value, context) {
					const path = context.path.split("[");
					const index = path[1]?.split("]")[0];
					const startTimeArray = context.options.context?.clockIn;
					const startTimeValue = startTimeArray ? startTimeArray[index] : "";

					if (!startTimeValue && !value) return true;

					return !!value;
				})
			)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	//prettier-ignore
	const onHandleGetList = useCallback(async (date) => {
		let response = null;

		try {
			let currentMonth = new Date().getMonth() + 1 === parseInt(dayjs(date.split("T")[0]).format("M"), 10);

			const payload = { employeeId: id, currentMonth: currentMonth, attendanceScheduledDate: date };

			response = await api.post.humanResource.scheduledAttendanceEmployee(payload);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const sortedResponse = response.sort((a, b) => a.seq - b.seq);
			
			setData(sortedResponse);
		}
	}, [id]);

	const generateMonthDates = (month, year) => {
		const dates = [];
		const firstDayOfMonth = new Date(year, month, 1);
		const lastDayOfMonth = new Date(year, month + 1, 0);

		for (let date = new Date(firstDayOfMonth); date <= lastDayOfMonth; date.setDate(date.getDate() + 1)) {
			dates.push({
				date: date.toLocaleString("sv", { year: "numeric", month: "2-digit", day: "2-digit" }),
				day: date.toLocaleString("sv", { day: "numeric" }),
				clockIn: null,
				clockOut: null
			});
		}

		return dates;
	};

	//prettier-ignore
	const editableDate = useCallback((month, year) => {
		const dates = generateMonthDates(month, year);
		let startTime = [];
		let endTime = [];

		dates.forEach((o, i) => {
			const matchDate = data.find((d) => d.seq === i + 1);

			if (matchDate) {
				startTime.push(matchDate?.clockIn ? dayjs(matchDate.clockIn) : null);
				endTime.push(matchDate?.clockIn ? dayjs(matchDate.clockOut) : null);
			} else {
				startTime.push(null);
				endTime.push(null);
			}
		});

		formik.setValues({ clockIn: startTime, clockOut: endTime });

		calendarRef.current.onHandleSetValue({ clockIn: startTime, clockOut: endTime });
	}, [data, formik]);

	const onHandleSetEditing = useCallback((status) => {
		setEditingStatus(status);
	}, []);

	//prettier-ignore
	const onHandleConfirmEdit = useCallback(async (values) => {
		let updatedData = data.map((item) => {
			const index = values.clockIn?.findIndex((_, i) => i + 1 === item?.seq);
			const clockInValue = values?.clockIn && index >= 0 && index < values.clockIn.length ? values.clockIn[index] : item?.clockIn;
			const clockOutValue = values?.clockOut && index >= 0 && index < values.clockOut.length ? values.clockOut[index] : item?.clockOut;

			return {
				id: item?.id,
				clockIn: clockInValue,
				clockOut: clockOutValue
			};
		});

		if (!updatedData) return;

		let response = null;

		try {
			await api.post.humanResource.updateScheduledAttendanceEmployee(updatedData);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			setData(updatedData);
		}

		calendarRef.current.updateMonthAndYear();
	}, [data]);

	//prettier-ignore
	const onHandleChange = useCallback(async (name, value) => {
		await formik.setFieldValue(name, value);

		calendarRef.current.onHandleFormik(name, value);
	}, [formik]);

	//prettier-ignore
	const onHandleSubmit = useCallback((values) => {
		calendarRef.current.onHandleShow(values);

		onHandleConfirmEdit(values);
	}, [onHandleConfirmEdit]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "clockIn",
			label: "Start Time",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					const rowData = tableMeta.rowData;
					const index = tableMeta.rowIndex;

					if (!editingStatus) {
						if (rowData[1]) return dayjs(value).format(DATE_TIME.HH_MM);

						return value;
					}

					return <AppInputTime required name={`clockIn[${index}]`} label="" placeholder="" value={formik.values.clockIn?.[index]} error={formik.errors.clockIn?.[index]} touched={formik.touched.clockIn?.[index]} onChange={onHandleChange} />;
				}
			}
		},
		{
			name: "clockOut",
			label: "End Time",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					const rowData = tableMeta.rowData;
					const index = tableMeta.rowIndex;

					if (!editingStatus) {
						if (rowData[2]) return dayjs(value).format(DATE_TIME.HH_MM);

						return;
					}

					return <AppInputTime required name={`clockOut[${index}]`} label="" placeholder="" value={formik.values.clockOut?.[index]} error={formik.errors.clockOut?.[index]} touched={formik.touched.clockOut?.[index]} onChange={onHandleChange} />;
				}
			}
		}
	], [editingStatus, formik, onHandleChange]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		customFooter: () => null
	}), []);

	useEffect(() => {
		const currentDate = dayjs(new Date()).utcOffset(0).format("YYYY-MM-DDTHH:mm:ss.sss[Z]");

		onHandleGetList(currentDate);
	}, [onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.HUMAN_RESOURCE.SCHEDULED_ATTENDANCE_EMPLOYEE);
		};
	}, [cancelRequest]);

	return (
		<div className="app-scheduled-attendance-employee-calendar">
			<div className="scheduled-attendance-employee-calendar">
				<form onSubmit={formik.handleSubmit}>
					<AppCalendarSchedule ref={calendarRef} formik={{ ...formik }} data={data} columns={tableColumns} options={tableOptions} editableDate={editableDate} onHandleGetList={onHandleGetList} onHandleSetEditing={onHandleSetEditing} />
				</form>
			</div>
		</div>
	);
};

export default AppScheduledAttendanceEmployeeCalendar;
