import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useState } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useDispatch } from "react-redux";

import api from "services/api";
import getInventoryLocationListing from "services/get-inventory-location-listing";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import { serveLayoutRequestErrors } from "common/serve-request-errors";

import ERRORS from "constants/errors";

import AppIcon from "components/app-icon";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";

import closeIcon from "assets/images/close-icon.png";

export const AppInventoryTransferModal = (props, ref) => {
	const dispatch = useDispatch();
	const [visible, setVisible] = useState(false);

	const initialValues = useMemo(() => {
		const values = { locationIdTo: "", quantity: "", quantityToTransfer: "" };

		return values;
	}, []);

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			locationIdTo: yup.string().required(ERRORS.REQUIRED),
			quantityToTransfer: yup.string().required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			const payload = { sourceInventoryId: values.id, quantity: values.quantityToTransfer, destinationLocationId: values.locationIdTo };

			await api.post.inventory.transferInventory(payload);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			setVisible(false);

			formik.resetForm();

			if (props?.onHandleGetDetails) {
				props.onHandleGetDetails(formik.values.id)
			} else {
				props.onHandleGetList();
			}

			dispatch(promptLayoutAlertMessage({ message: "Inventory was transfered successfully!" }));
		}
	}, [formik, props, dispatch]);

	//prettier-ignore
	const onHandleShow = useCallback((values) => {
		formik.setValues({ ...values, locationIdTo: "", quantityToTransfer: 0 });

		setVisible(true);
	}, [formik]);

	const onHandleDismiss = useCallback(() => {
		setVisible(false);

		formik.resetForm();
	}, [formik]);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleDismiss: onHandleDismiss
	}));

	return (
		<Modal classes={{ root: "app-inventory-transfer-modal" }} open={visible}>
			<div className="inventory-transfer-modal">
				<button type="button" className="inventory-transfer-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<h1 className="inventory-transfer-modal__title">Transfer from {formik.values.inventoryLocation?.name}</h1>

				<form className="inventory-transfer-modal__form" onSubmit={formik.handleSubmit}>
					<AppSelectInput required name="locationIdTo" label="Location" placeholder="Select..." loadOptions={(payload) => getInventoryLocationListing({ id: formik.values.id, keyword: payload?.keyword })} value={formik.values.locationIdTo} error={formik.errors.locationIdTo} touched={formik.touched.locationIdTo} onChange={formik.handleChange} />

					<AppInput disabled type="text" name="quantity" label="Available Quantity" placeholder="Available Quantity" value={formik.values.quantity} error={formik.errors.quantity} touched={formik.touched.quantity} onChange={formik.handleChange} />

					<AppInput required type="number" name="quantityToTransfer" label="Quantity to Transfer" placeholder="Enter a number that is higher than 0" value={formik.values.quantityToTransfer} error={formik.errors.quantityToTransfer} touched={formik.touched.quantityToTransfer} onChange={formik.handleChange} />

					<div className="inventory-transfer-modal__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

						<AppButton type="submit" label="Confirm" disabled={formik.isSubmitting} />
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppInventoryTransferModal));

AppInventoryTransferModal.propTypes = {
	ref: PropTypes.object
};
