import React, { forwardRef, memo, useCallback, useImperativeHandle, useMemo, useRef, useState } from "react";

import * as yup from "yup";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { Modal } from "@mui/material";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";

import api from "services/api";
import getServiceListing from "services/get-service-listing";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import formatCurrencyPattern from "common/format-currency-pattern";
import { serveLayoutRequestErrors } from "common/serve-request-errors";

import ERRORS from "constants/errors";
import QUOTATION_ITEM_SERVICE_TYPE from "constants/quotation-item-service-type";

import AppIcon from "components/app-icon";
import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";

import closeIcon from "assets/images/close-icon.png";

export const AppSalesOrderAddItemModal = (props, ref) => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const createSubItem = useRef(false);
	const [visible, setVisible] = useState(false);
	const [parentItemId, setParentItemId] = useState("");
	const [isSubItem, setIsSubItem] = useState("");
	const [isEditMode, setIsEditMode] = useState(false);
	const [selectedItemIndex, setSelectedItemIndex] = useState(null);
	const [selectedSubItemIndex, setSelectedSubItemIndex] = useState(null);
	const [referenceNum, setReferenceNum] = useState("");
	const submitLabel = useMemo(() => (isEditMode ? "Update" : "Add"), [isEditMode]);

	const initialValues = useMemo(() => {
		const values = {
			title: "",
			description: "",
			serviceType: "",
			quantity: null,
			unitPrice: null,
			unitName: "",
			serviceId: "",
			lineItemTotalAmount: ""
		};

		return values;
	}, []);

	const serviceType = [
		{ label: "Chargeable", value: QUOTATION_ITEM_SERVICE_TYPE.CHARGEABLE },
		{ label: "Non-chargeable", value: QUOTATION_ITEM_SERVICE_TYPE.NONCHARGEABLE },
		{ label: "Inclusive", value: QUOTATION_ITEM_SERVICE_TYPE.INCLUSIVE },
		{ label: "Complimentary", value: QUOTATION_ITEM_SERVICE_TYPE.COMPLIMENTARY }
	];

	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			title: yup.string().required(ERRORS.REQUIRED),
			serviceType: yup.string().required(ERRORS.REQUIRED),
			quantity: yup.string().required(ERRORS.REQUIRED),
			unitPrice: yup.string().required(ERRORS.REQUIRED),
			unitName: yup.string().required(ERRORS.REQUIRED)
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	//prettier-ignore
	const onHandleDismiss = useCallback(() => {
		createSubItem.current = false;

		setIsEditMode(false);
		setSelectedItemIndex(null);
		setSelectedSubItemIndex(null);
		setVisible(false);
		setIsSubItem(false);

		formik.resetForm();
	}, [formik]);

	//prettier-ignore
	const onHandleEditMode = useCallback(async (updatedValues) => {
		let response = null;

		props.onEdit(updatedValues, selectedItemIndex, selectedSubItemIndex);

		const payload = {
			title: updatedValues.title,
			description: updatedValues.description,
			serviceType: updatedValues.serviceType,
			quantity: updatedValues.quantity,
			unitPrice: updatedValues.unitPrice,
			unitName: updatedValues.unitName,
			id: updatedValues.id
		};

		if (updatedValues.lineSubItems) {
			payload.lineItemId = updatedValues.id;
			payload.serviceId = updatedValues.configService.id;

			try {
				response = await api.post.salesOrders.updateItem(payload);
			} catch (error) {
				serveLayoutRequestErrors(error);
			}

			if (response) {
				dispatch(promptLayoutAlertMessage({ message: "Item was updated successfully!" }));
			}
		} else {
			try {
				response = await api.post.salesOrders.updateSubItem(payload);
			} catch (error) {
				serveLayoutRequestErrors(error);
			}

			if (response) {
				dispatch(promptLayoutAlertMessage({ message: "Sub-Item was updated successfully!" }));
			}
		}
	}, [props, selectedItemIndex, selectedSubItemIndex, dispatch]);

	//prettier-ignore
	const onHandleCreateMode = useCallback(async (updatedValues) => {
		let response = null;

		if (createSubItem.current) {
			props.onConfirmSubItem(updatedValues);

			if (!isSubItem) return;

			const subItemData = {
				...updatedValues,
				lineItemId: parentItemId
			};

			try {
				response = await api.post.salesOrders.createSubItem(subItemData);
			} catch (error) {
				serveLayoutRequestErrors(error);
			}

			if (response) {
				props.onConfirm({ ...updatedValues, ...response.data });

				dispatch(promptLayoutAlertMessage({ message: "Sub-Item was added successfully!" }));
			}
		} else {
			props.onConfirm(updatedValues);

			const payload = {
				title: updatedValues.title,
				description: updatedValues.description,
				serviceType: updatedValues.serviceType,
				quantity: updatedValues.quantity,
				unitPrice: parseFloat(updatedValues.unitPrice).toFixed(2),
				unitName: updatedValues.unitName,
				quotationId: props.quotationId,
				serviceId: updatedValues.serviceId
			};

			try {
				response = await api.post.salesOrders.createItem(payload);
			} catch (error) {
				serveLayoutRequestErrors(error);
			}

			if (response) {
				props.onConfirm({ ...updatedValues, ...response.data });

				dispatch(promptLayoutAlertMessage({ message: "Item was added successfully!" }));
			}
		}
	}, [props, isSubItem, parentItemId, dispatch]);

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		const updatedValues = {
			...values,
			id: id,
			referenceNo: referenceNum || "",
			unitPrice: parseFloat(values.unitPrice).toFixed(2),
			lineItemTotalAmount: calcAmount(values.quantity || 0, values.unitPrice || 0)
		};

		if (isEditMode) {
			await onHandleEditMode(updatedValues);
		} else {
			await onHandleCreateMode(updatedValues);
		}

		onHandleDismiss();
	}, [id, referenceNum, isEditMode, onHandleDismiss, onHandleEditMode, onHandleCreateMode]);

	const calcAmount = (quantity, unitPrice) => {
		const transformedAmt = Number(quantity) * parseFloat(unitPrice);

		return transformedAmt.toFixed(2);
	};

	//prettier-ignore
	const onHandleShow = useCallback(() => {
		setIsEditMode(false);
		setVisible(true);
	}, []);

	//prettier-ignore
	const onHandleShowEdit = useCallback((itemData, itemIndex, subItemIndex) => {
		setIsEditMode(true);
		setSelectedItemIndex(itemIndex);
		setSelectedSubItemIndex(subItemIndex);
		
		createSubItem.current = false;

		formik.setValues(itemData);

		if (itemData?.configService?.id) {
			formik.setFieldValue("serviceId", itemData?.configService?.id);
		}

		setVisible(true);
	}, [formik]);

	//prettier-ignore
	const onHandleShowSubItem = useCallback((parentItemId) => {
		createSubItem.current = true;

		setParentItemId(parentItemId);
		setIsSubItem(true);
		setVisible(true);
	}, []);

	const onHandleGetReferenceNo = (event) => {
		const serviceId = event.target.value;

		getReferenceNo(serviceId);

		formik.setValues({
			...formik.values,
			serviceId: serviceId
		});
	};

	const getReferenceNo = useCallback(async (uniqueId) => {
		let response = null;

		try {
			response = await api.get.serviceListing.serviceListing(uniqueId);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			setReferenceNum(response.referenceNo);
		}
	}, []);

	useImperativeHandle(ref, () => ({
		onHandleShow: onHandleShow,
		onHandleShowSubItem: onHandleShowSubItem,
		onHandleDismiss: onHandleDismiss,
		onHandleShowEdit: onHandleShowEdit
	}));

	const modalTitle = useMemo(() => {
		if (isEditMode) {
			return isSubItem ? "Edit Sub Item" : "Edit Item";
		} else {
			return isSubItem ? "Add Sub Item" : "Add Item";
		}
	}, [isEditMode, isSubItem]);

	const itemAmount = useMemo(() => {
		return (formik.values.quantity * formik.values.unitPrice).toFixed(2);
	}, [formik.values.quantity, formik.values.unitPrice]);

	return (
		<Modal classes={{ root: "app-sales-order-add-item-modal" }} open={visible}>
			<div className="sales-order-add-item-modal">
				<button type="button" className="sales-order-add-item-modal__close" onClick={onHandleDismiss}>
					<AppIcon src={closeIcon} />
				</button>

				<h1 className="sales-order-add-item-modal__title">{modalTitle}</h1>

				<form className="sales-order-add-item-modal__form" onSubmit={formik.handleSubmit}>
					<AppInput required type="text" name="title" label="Title" placeholder="Enter Title" value={formik.values.title} error={formik.errors.title} touched={formik.touched.title} onChange={formik.handleChange} />

					<AppInput multiline maxLength={255} type="textarea" name="description" label="Description" placeholder="Enter Description" value={formik.values.description} error={formik.errors.description} touched={formik.touched.description} onChange={formik.handleChange} />

					{!createSubItem.current && <AppSelectInput name="serviceId" label="Service" placeholder="Search from General Configuration Services" loadOptions={getServiceListing} value={formik.values.serviceId} error={formik.errors.serviceId} touched={formik.touched.serviceId} onChange={onHandleGetReferenceNo} />}

					<AppSelectInput searchable={false} required name="serviceType" label="Type" placeholder="Select..." options={serviceType} value={formik.values.serviceType} error={formik.errors.serviceType} touched={formik.touched.serviceType} onChange={formik.handleChange} />

					<AppInput required type="number" name="quantity" label="Quantity" placeholder="Please input Quantity" value={formik.values.quantity} error={formik.errors.quantity} touched={formik.touched.quantity} onChange={formik.handleChange} />

					<div className="sales-order-add-item-modal__row">
						<AppInput required type="text" name="unitPrice" label="Price Per Unit (MYR)" placeholder="Please input per unit amount" value={formik.values.unitPrice} error={formik.errors.unitPrice} touched={formik.touched.unitPrice} onChange={formik.handleChange} onFormat={formatCurrencyPattern} />

						<AppInput required type="text" name="unitName" label="Unit" placeholder="Enter Unit" value={formik.values.unitName} error={formik.errors.unitName} touched={formik.touched.unitName} onChange={formik.handleChange} />
					</div>

					<AppInput disabled type="text" name="lineItemTotalAmount" label="Amount (MYR)" value={itemAmount} touched={formik.values.lineItemTotalAmount} onChange={formik.handleChange} onFormat={formatCurrencyPattern} />

					<div className="sales-order-add-item-modal__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleDismiss} />

						<AppButton type="submit" label={submitLabel} disabled={formik.isSubmitting} />
					</div>
				</form>
			</div>
		</Modal>
	);
};

export default memo(forwardRef(AppSalesOrderAddItemModal));

AppSalesOrderAddItemModal.propTypes = {
	onConfirm: PropTypes.func.isRequired,
	onConfirmSubItem: PropTypes.func.isRequired,
	onEdit: PropTypes.func.isRequired,
	quotationId: PropTypes.string.isRequired
};
