import { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import { debounce } from "lodash";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import api from "services/api";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import convertPaginationTableData from "common/convert-pagination-table-data";
import serveRequestErrors, { serveLayoutRequestErrors } from "common/serve-request-errors";

import PAGE from "constants/page";
import ROLES from "constants/roles";
import ENDPOINT_PATH from "constants/end-point-path";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppTable from "components/app-table/app-table";
import AppReorderSubtaskModal from "components/pages/operations/general-configuration/app-reorder-subtask-modal";
import AppCreateEditSubtaskModal from "components/pages/operations/general-configuration/app-create-edit-subtask-modal";

import editIcon from "assets/images/edit-icon.png";
import trashIcon from "assets/images/trash-icon.png";
import searchIcon from "assets/images/search-icon.png";
import addBlueIcon from "assets/images/add-blue-icon.png";
import moreIcon from "assets/images/vertical-breadcrumbs-icon.png";

const AppSubtasksTable = (props) => {
	const profile = useSelector((state) => state.profile);
	const dispatch = useDispatch();
	const { id } = useParams();
	const reorderSubtaskModalRef = useRef();
	const createEditSubtaskModalRef = useRef();
	const selectedSubTaskDataRef = useRef(null);
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const accessible = useMemo(() => profile?.permissions?.[ROLES.SERVICE_CONFIG], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);
	const [searchParams, setSearchParams] = useSearchParams();
	const memoSearchParams = useRef(setSearchParams);
	const queryParams = useRef({ page: 0, "subtask-action": searchParams.get("subtask-action") || "", input: searchParams.get("input") || "" });
	const [subtaskTableAnchor, setSubtaskTableAnchor] = useState(null);
	const values = useMemo(() => props.values, [props.values]);
	const [data, setData] = useState(convertPaginationTableData());

	const onHandleCloseSubtaskTableMenu = useCallback(() => {
		selectedSubTaskDataRef.current = null;

		setSubtaskTableAnchor(null);
	}, []);

	// prettier-ignore
	const onToggleTableAction = useCallback((event, tableMeta) => {
		selectedSubTaskDataRef.current = { ...data.content[tableMeta.rowIndex], subtaskIndex: tableMeta.rowIndex };

		setSubtaskTableAnchor(event.currentTarget);
	}, [data.content]);

	const onHandleCreateSubtaskModal = useCallback(() => {
		setSubtaskTableAnchor(null);

		createEditSubtaskModalRef.current.onHandleShow({ status: PAGE.CREATE, subtasks: values.subtasks });
	}, [values.subtasks]);

	const onHandleEditSubtaskModal = useCallback(() => {
		setSubtaskTableAnchor(null);

		createEditSubtaskModalRef.current.onHandleShow({ ...selectedSubTaskDataRef.current, status: PAGE.EDIT, subtasks: values.subtasks });
	}, [values.subtasks]);

	const onHandleReorderSubtaskModal = useCallback(() => {
		reorderSubtaskModalRef.current.onHandleShow({ ...data.content, subtasks: values.subtasks });
	}, [data.content, values.subtasks]);

	const onHandleGetData = useCallback(() => {
		let initialData = structuredClone(values.subtasks);
		let nextData = [];

		const params = sanitizeObject({ ...queryParams.current, size: 10 });

		memoSearchParams.current(params);

		const lowerCaseSearch = queryParams.current["subtask-action"]?.toLowerCase();

		nextData = initialData.filter((o) => {
			return o.subtaskAction.toLowerCase().includes(lowerCaseSearch) || o.input.label.toLowerCase().includes(lowerCaseSearch);
		});

		setData({ content: nextData });
	}, [values.subtasks]);

	const onHandleGetList = useCallback(async () => {
		if (isCreate) {
			onHandleGetData();
		}

		if (!isCreate) {
			let response = null;

			try {
				const params = sanitizeObject({ ...queryParams.current, "config-service-id": id, size: 10 });

				memoSearchParams.current(params);

				response = await api.get.serviceListing.subtasks(params);
			} catch (error) {
				serveLayoutRequestErrors(error);
			}

			if (response) {
				const obj = convertPaginationTableData(response);

				obj.content.sort((a, b) => a.seq - b.seq);

				setData(obj);
			}
		}
	}, [isCreate, onHandleGetData, id]);

	//prettier-ignore
	const onHandleSearch = useCallback((event) => {
		queryParams.current["subtask-action"] = event.target.value;

		onHandleGetList();
	}, [onHandleGetList]);

	const onHandleDebounceSearch = debounce(onHandleSearch, 1000);

	const onHandleRemoveSubtask = useCallback(async () => {
		setSubtaskTableAnchor(null);

		let response = null;

		const subtasksCopy = structuredClone(values.subtasks);

		if (isCreate) {
			let nextSubtasks = subtasksCopy.filter((o, i) => i !== selectedSubTaskDataRef.current.subtaskIndex);

			props.onHandleUpdateSubtasks(nextSubtasks);
		}

		if (!isCreate) {
			try {
				const nextSubtasks = subtasksCopy.filter((o) => o.id === selectedSubTaskDataRef.current.id);
				const nextSubtaskIds = nextSubtasks.map((o) => o.id);
				const payload = { configServiceId: id, subtaskIds: nextSubtaskIds };

				await api.post.serviceListing.deleteServiceSubtask(payload);

				response = true;
			} catch (error) {
				serveRequestErrors(error);
			}

			if (response) {
				props.onHandleGetDetails(id);

				onHandleGetList();

				dispatch(promptLayoutAlertMessage({ message: "Subtask was removed successfully!" }));
			}
		}
	}, [isCreate, values.subtasks, props, dispatch, id, onHandleGetList]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "seq",
			label: "#",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "subtaskAction",
			label: "Subtask Action",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "input.label",
			label: "Input",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "edit",
			label: "Action",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__action" onClick={(e) => onToggleTableAction(e, tableMeta)}>
							<img src={moreIcon} alt="edit-icon" />
						</button>
					);
				}
			}
		}
	], [onToggleTableAction]);

	const emptyState = useMemo(() => {
		if (data.content.length) return {};

		const node = () => (
			<tbody>
				<tr className="table__empty-state">
					<td colSpan={tableColumns.length} align="center">
						<p className="table__text">
							No Subtasks Linked.
							<span className="table__link" onClick={onHandleCreateSubtaskModal}>
								Add Subtasks?
							</span>
						</p>
					</td>
				</tr>
			</tbody>
		);

		return { TableBody: node };
	}, [data, tableColumns, onHandleCreateSubtaskModal]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		enableNestedDataAccess: ".",
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList();
					break;
				default:
					break;
			}
		}
	}), [data, onHandleGetList]);

	useEffect(() => {
		onHandleGetList();
	}, [onHandleGetList]);

	useEffect(() => {
		const subtasksCopy = structuredClone(values.subtasks);

		setData({ content: subtasksCopy });
	}, [values.subtasks]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.SERVICE_LISTING.SUBTASKS);
		};
	}, [cancelRequest]);

	return (
		<div className="app-subtasks-table">
			<div className="subtasks-table">
				<div className="subtasks-table__header">
					<div className="subtasks-table__search-input">
						<AppInput type="text" name="search" placeholder="Search by Subtask Action" endIcon={searchIcon} iconPosition="end" defaultValue={queryParams.current["subtask-action"]} onChange={onHandleDebounceSearch} />
					</div>

					<div className="subtasks-table__interact">
						<div className="subtasks-table__interact-button">
							<AppButton type="button" label="Reorder" icon={addBlueIcon} disabled={restricted} onClick={onHandleReorderSubtaskModal} />
						</div>

						<div className="subtasks-table__interact-button">
							<AppButton type="button" label="Add" icon={addBlueIcon} disabled={restricted} onClick={onHandleCreateSubtaskModal} />
						</div>
					</div>
				</div>

				<AppTable data={data.content} columns={tableColumns} options={tableOptions} components={emptyState} />
			</div>

			{/* prettier-ignore */}
			<Menu classes={{ root: "subtasks-table-menu" }} anchorEl={subtaskTableAnchor} open={!!subtaskTableAnchor} onClose={onHandleCloseSubtaskTableMenu} anchorOrigin={{ vertical: "bottom", horizontal: "right" }} transformOrigin={{ vertical: "top", horizontal: "right" }}>
				<MenuItem onClick={onHandleEditSubtaskModal}>
					<img src={editIcon} alt="subtask-edit" />
					Edit
				</MenuItem>

				<MenuItem onClick={onHandleRemoveSubtask} className="subtasks-table-menu__remove">
					<img src={trashIcon} alt="subtask-remove" />
					Remove
				</MenuItem>
			</Menu>

			<AppCreateEditSubtaskModal ref={createEditSubtaskModalRef} onHandleUpdateSubtasks={props.onHandleUpdateSubtasks} onHandleGetList={onHandleGetList} onHandleGetDetails={props.onHandleGetDetails} />

			<AppReorderSubtaskModal ref={reorderSubtaskModalRef} onHandleUpdateSubtasks={props.onHandleUpdateSubtasks} onHandleRefreshTable={onHandleGetList} onHandleGetDetails={props.onHandleGetDetails} />
		</div>
	);
};

export default AppSubtasksTable;
