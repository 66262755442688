import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useDispatch } from "react-redux";
import { useParams, useSearchParams } from "react-router-dom";
import { AxiosContext } from "contexts/with-interceptor-provider";

import api from "services/api";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import classNames from "common/class-names";
import getTotalDays from "common/get-total-days";
import sanitizeObject from "common/sanitize-object";
import convertSortingQuery from "common/convert-sorting-query";
import { serveLayoutRequestErrors } from "common/serve-request-errors";
import convertPaginationTableData from "common/convert-pagination-table-data";

import STATUS from "constants/status";
import DATE_TIME from "constants/date-time";
import ENDPOINT_PATH from "constants/end-point-path";

import AppStatus from "components/app-status";
import AppCheckbox from "components/app-checkbox";
import AppInputDate from "components/app-input-date";
import AppTable from "components/app-table/app-table";
import AppTableFilterHeader from "components/app-table-filter-header";
import AppViewLeaveDetailsModal from "components/pages/human-resources/app-view-leave-details-modal";
import AppViewEmployeePendingLeaveRejectionModal from "components/pages/human-resources/app-view-employee-pending-leave-rejection-modal";

import eyeOpenIcon from "assets/images/eye-open-icon.png";

const AppViewEmployeeLeaveHistoryTable = (props) => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const cancelRequest = useContext(AxiosContext).onHandleCancelRequest;
	const leaveHistoryRef = useRef();
	const rejectionRef = useRef();
	const [searchParams, setSearchParams] = useSearchParams();
	const isHR = useMemo(() => props.isHR, [props.isHR]);
	const defaultStatus = useMemo(() => [STATUS.APPROVED, STATUS.REJECTED, STATUS.CANCELLED].join(","), []);
	const [data, setData] = useState(convertPaginationTableData());
	const queryParams = useRef({ page: 0, name: searchParams.get("keyword") || "", status: defaultStatus, tab: "LEAVE_HISTORY" });
	const memoSearchParams = useRef(setSearchParams);

	const validationSchema = yup.object({
		endDate: yup.date().test(function (value, { parent }) {
			if (!value) return true;

			const totalDays = getTotalDays(parent.startDate, value);

			return totalDays >= 0 && totalDays <= 90;
		})
	});
	const formik = useFormik({
		initialValues: { submitDate: "", startDate: "", endDate: "", leaveType: "", days: "", status: [STATUS.APPROVED, STATUS.REJECTED, STATUS.CANCELLED] },
		validationSchema: validationSchema,
		onSubmit: (values) => {
			onHandleSubmit(values);
		}
	});

	//prettier-ignore
	const intructionClassName = useMemo(() => classNames({
		"filter-menu__instruction": true,
		"filter-menu__instruction--error": formik.errors.endDate
	}), [formik.errors.endDate]);

	const menuFilterStatus = useMemo(() => {
		const data = [
			{ label: "Approved", name: STATUS.APPROVED },
			{ label: "Rejected", name: STATUS.REJECTED },
			{ label: "Cancelled", name: STATUS.CANCELLED }
		];

		return data;
	}, []);

	const onHandleSearch = useCallback((event) => {
		queryParams.current.keyword = event.target.value;
	}, []);

	const onHandleClearFilter = useCallback(() => {
		formik.resetForm();

		queryParams.current["start-date"] = "";
		queryParams.current["end-date"] = "";
	}, [formik]);

	//prettier-ignore
	const onHandleSelectStatus = useCallback((value, name) => {
		let values = [...formik.values.status];

		if (formik.values.status.length < 2 && !value) return;

		if (!value) {
			values = values.filter((o) => o !== name);
		} else {
			values.push(name);
		}

		formik.setFieldValue("status", values);
	}, [formik]);

	const onHandleSubmit = useCallback((values) => {
		queryParams.current["start-date"] = values.startDate && dayjs(values.startDate).isValid() ? dayjs(values.startDate).format(DATE_TIME.YYYY_MM_DD_HH_MM_SS) : "";
		queryParams.current["end-date"] = values.endDate && dayjs(values.endDate).isValid() ? dayjs(values.endDate).format(DATE_TIME.YYYY_MM_DD_HH_MM_SS) : "";
	}, []);

	const onHandleSetLastFilter = useCallback(() => {
		formik.setFieldValue("status", queryParams.current.status.split(","));
		formik.setFieldValue("start-date", queryParams.current["start-date"]);
		formik.setFieldValue("end-date", queryParams.current["end-date"]);
	}, [formik]);

	//prettier-ignore
	const onHandleViewLeaveHistory = useCallback((obj) => {
		leaveHistoryRef.current.onHandleShow(data.content[obj.rowIndex]);
	}, [data]);

	const onHandleGetList = useCallback(async (id) => {
		let response = null;

		const params = sanitizeObject({ ...queryParams.current, "employee-id": id, size: 10 });

		try {
			memoSearchParams.current(params);

			response = await api.get.humanResource.employeeLeaveHistory(params);
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			const obj = convertPaginationTableData(response);

			setData(obj);
		}
	}, []);

	//prettier-ignore
	const onHandleRejectReason = useCallback(async (values, ids) => {
		let response = null;

		try {
			const payload = { leaveRequestIds: ids, rejectReason: values };

			await api.post.humanResource.rejectPendingLeave(payload);

			response = true;
		} catch (error) {
			serveLayoutRequestErrors(error);
		}

		if (response) {
			dispatch(promptLayoutAlertMessage({ message: "Leave was rejected successfully" }));

			onHandleGetList();
		}
	}, [dispatch, onHandleGetList]);

	const onHandleRejectLeave = useCallback((id) => {
		rejectionRef.current.onHandleShow(id);
	}, []);

	//prettier-ignore
	const onHandleBack = useCallback((id) => {
		const backData = data.content.filter((item) => id.includes(item.id));

		leaveHistoryRef.current.onHandleShow(backData[0]);
	}, [data]);

	//prettier-ignore
	const tableColumns = useMemo(() => [
		{
			name: "paginationNumbers",
			label: "#",
			options: {
				sort: false
			}
		},
		{
			name: "leaveType",
			label: "leave Type",
			options: {
				sort: true,
				sortThirdClickReset: true
			}
		},
		{
			name: "days",
			label: "Days",
			options: {
				sort: false
			}
		},
		{
			name: "submitDate",
			label: "Submit Date",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.DD_MM_YYYY)
			}
		},
		{
			name: "startDate",
			label: "Start Date",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.DD_MM_YYYY)
			}
		},
		{
			name: "endDate",
			label: "End Date",
			options: {
				sort: true,
				sortThirdClickReset: true,
				customBodyRender: (value) => dayjs(value).format(DATE_TIME.DD_MM_YYYY)
			}
		},
		{
			name: "status",
			label: "Status",
			options: {
				sort: false,
				customBodyRender: (value) => <AppStatus status={value} />
			}
		},
		{
			name: "view",
			label: "View",
			options: {
				sort: false,
				customBodyRender: (value, tableMeta) => {
					return (
						<button type="button" className="table__view" onClick={() => onHandleViewLeaveHistory(tableMeta)}>
							<img src={eyeOpenIcon} alt="expand-icon" />
						</button>
					);
				}
			}
		}
	], [onHandleViewLeaveHistory]);

	//prettier-ignore
	const tableOptions = useMemo(() => ({
		count: data.totalElements,
		page: data.page,
		serverSide: true,
		onTableChange: (action, tableState) => {
			switch (action) {
				case "changePage":
					queryParams.current.page = tableState.page;

					onHandleGetList();
					break;
				case "sort":
					queryParams.current = { ...queryParams.current, sort: convertSortingQuery(tableState.sortOrder) };

					onHandleGetList();
					break;
				default:
					break;
			}
		}
	}), [data, onHandleGetList]);

	useEffect(() => {
		onHandleGetList(id);
	}, [id, onHandleGetList]);

	useEffect(() => {
		return () => {
			cancelRequest(ENDPOINT_PATH.HUMAN_RESOURCE.EMPLOYEE_LEAVE_HISTORY);
		};
	}, [cancelRequest]);

	return (
		<div className="app-view-employee-leave-history-table">
			<div className="view-employee-leave-history-table">
				<div className="view-employee-leave-history-table__header">
					<AppTableFilterHeader formik={{ ...formik }} searchPlaceholder="Search by Leave Type" searchDefaultValue={queryParams.current.keyword} onHandleSearch={onHandleSearch} onHandleClearFilter={onHandleClearFilter} onHandleSetLastFilter={onHandleSetLastFilter}>
						<div className="view-employee-leave-history-table-filter">
							<div className="view-employee-leave-history-table-filter__container">
								<div className="view-employee-leave-history-table-filter__inputs">
									<AppInputDate name="scheduledStartDate" label="Scheduled Date" placeholder="Start Date" onChange={formik.setFieldValue} value={formik.values.scheduledStartDate} />

									<AppInputDate name="scheduledEndDate" placeholder="End Date" onChange={formik.setFieldValue} value={formik.values.scheduledEndDate} />
								</div>

								<p className={intructionClassName}>Please select a date or a date range of up to 90 days</p>
							</div>

							<div className="view-employee-leave-history-table-filter__container">
								<p className="view-employee-leave-history-table-filter__label">Status</p>

								<div className="view-employee-leave-history-table-filter__checkbox-status">
									{menuFilterStatus.map((o) => {
										const isActiveStatus = formik.values.status.findIndex((i) => i === o.name) > -1;

										return (
											<div className="view-employee-leave-history-table-filter__checkbox" key={o.label}>
												<AppCheckbox key={o.label} onClick={(v) => onHandleSelectStatus(v, o.name)} label={o.label} value={isActiveStatus} />
											</div>
										);
									})}
								</div>
							</div>
						</div>
					</AppTableFilterHeader>
				</div>

				<AppTable data={data.content} columns={tableColumns} options={tableOptions} />
			</div>

			<AppViewLeaveDetailsModal ref={leaveHistoryRef} onHandleRejectLeave={onHandleRejectLeave} isHR={isHR} />

			<AppViewEmployeePendingLeaveRejectionModal ref={rejectionRef} onHandleRejectReason={onHandleRejectReason} onHandleBack={onHandleBack} />
		</div>
	);
};

export default AppViewEmployeeLeaveHistoryTable;
