import { Fragment, memo, useCallback, useEffect, useMemo } from "react";

import * as yup from "yup";
import dayjs from "dayjs";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import pathnames from "routes/pathnames";

import api from "services/api";
import getServiceTypeListing from "services/get-service-type-listing";
import getServiceInputListing from "services/get-service-input-listing";
import getServiceStatusListing from "services/get-service-status-listing";

import useBreadcrumb from "hooks/use-breadcrumb";

import { promptLayoutAlertMessage } from "store/slices/layout-alert";

import serveRequestErrors from "common/serve-request-errors";

import PAGE from "constants/page";
import ROLES from "constants/roles";
import ERRORS from "constants/errors";
import DATE_TIME from "constants/date-time";
import ENDPOINT_PATH from "constants/end-point-path";

import AppInput from "components/app-input";
import AppButton from "components/app-button";
import AppSelectInput from "components/app-select-input";
import AppSubtasksTable from "components/pages/operations/general-configuration/app-subtasks-table";

const PageServiceListingCreateEdit = (props) => {
	const profile = useSelector((state) => state.profile);
	const dispatch = useDispatch();
	const { id } = useParams();
	const navigate = useNavigate();
	const isCreate = useMemo(() => id === PAGE.CREATE, [id]);
	const title = useMemo(() => (isCreate ? "Add Service" : "Edit Service"), [isCreate]);
	const submitLabel = useMemo(() => (!isCreate ? "Update" : "Add"), [isCreate]);
	const accessible = useMemo(() => profile?.permissions?.[ROLES.SERVICE_CONFIG], [profile]);
	const restricted = useMemo(() => !accessible?.update || !accessible?.create, [accessible]);

	// prettier-ignore
	const initialValues = useMemo(() => ({ serviceId: "", status: "", serviceType: "", serviceAction: "", input: "", remarks: "", lastModifiedBy: "", lastModifiedByName: "", lastModifiedDate: "", subtasks: [] }), []);

	// prettier-ignore
	const formik = useFormik({
		initialValues: initialValues,
		validationSchema: yup.object({
			status: yup.string().required(ERRORS.REQUIRED),
			serviceType: yup.string().required(ERRORS.REQUIRED),
			serviceAction: yup.string().required(ERRORS.REQUIRED),
			input: yup.string().required(ERRORS.REQUIRED),
			remarks: yup.string().required(ERRORS.REQUIRED),
		}),
		onSubmit: (values) => {
			onHandleSubmit(values);
		},
	});

	const breadCrumb = useMemo(() => {
		const data = [
			{ label: "Operations", path: pathnames.operations.generalConfiguration + "?tab=SERVICE_LISTING" },
			{ label: "General Configuration", path: pathnames.operations.generalConfiguration + "?tab=SERVICE_LISTING" }
		];

		if (isCreate) {
			data.push({ label: "Add Service Listing", path: pathnames.operations.serviceListingCreateEdit + PAGE.CREATE });
		}

		if (!isCreate) {
			data.push({ label: "Edit Service Listing " + formik.values.serviceId, path: pathnames.operations.serviceListingCreateEdit + id });
		}

		return data;
	}, [isCreate, formik, id]);

	useBreadcrumb({ breadCrumb });

	const memoSetFormValues = useMemo(() => formik.setValues, [formik.setValues]);

	const memoCancelRequest = useMemo(() => props.onHandleCancelRequest, [props.onHandleCancelRequest]);

	//prettier-ignore
	const onHandleSubmit = useCallback(async (values) => {
		let response = null;

		try {
			const payload = {
				status: values.status,
				serviceType: values.serviceType,
				serviceAction: values.serviceAction,
				inputId: values.input,
				remarks: values.remarks
			};

			if (isCreate) {
				payload.subtasks = values.subtasks;

				await api.post.serviceListing.createServiceListing(payload);
			}

			if (!isCreate) {
				payload.id = id;

				await api.post.serviceListing.updateServiceListing(payload);
			}

			response = true;
		} catch (error) {
			serveRequestErrors(error);
		} finally {
			formik.setSubmitting(false);
		}

		if (response) {
			if (isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Service was added successfully!" }));
			}

			if (!isCreate) {
				dispatch(promptLayoutAlertMessage({ message: "Service was updated successfully!" }));
			}

			navigate(pathnames.operations.generalConfiguration + "?tab=SERVICE_LISTING");
		}
	}, [navigate, dispatch, formik, id, isCreate]);

	//prettier-ignore
	const onHandleGetDetails = useCallback(async (uniqueId) => {
		let response = null;

		try {
			response = await api.get.serviceListing.serviceListing(uniqueId);
		} catch (error) {
			promptLayoutAlertMessage(error);
		}

		if (response) {
			memoSetFormValues({
				serviceId: response.referenceNo,
				serviceType: response.serviceType,
				serviceAction: response.serviceAction,
				input: response?.input?.id ?? "",
				remarks: response.remarks,
				status: response.status,
				lastModifiedBy: response.lastModifiedBy,
				lastModifiedByName: response.lastModifiedByName,
				lastModifiedDate: dayjs(response.lastModifiedDate).format(DATE_TIME.LAST_UPDATED_BY_DATE),
				subtasks: response.subtasks
			});
		}
	}, [memoSetFormValues]);

	// prettier-ignore
	const onHandleUpdateSubtasks = useCallback((values) => {
		formik.setFieldValue("subtasks", [...values]);
	}, [formik]);

	const onHandleCancel = useCallback(() => {
		navigate(pathnames.operations.generalConfiguration + "?tab=SERVICE_LISTING");
	}, [navigate]);

	const SubtasksTable = useCallback((obj) => {
		return (
			<Fragment>
				<div className="service-listing-create-edit__label">SubTasks</div>

				<div className="service-listing-create-edit__table">
					<AppSubtasksTable values={obj.values} onHandleUpdateSubtasks={obj.onHandleUpdateSubtasks} onHandleGetDetails={obj.onHandleGetDetails} />
				</div>
			</Fragment>
		);
	}, []);

	useEffect(() => {
		if (!isCreate) onHandleGetDetails(id);

		return () => {
			if (id) memoCancelRequest(ENDPOINT_PATH.SERVICE_LISTING.SERVICE_LISTING);
		};
	}, [isCreate, id, memoCancelRequest, onHandleGetDetails]);

	return (
		<div className="page-service-listing-create-edit">
			<div className="service-listing-create-edit">
				<div className="service-listing-create-edit__header">
					<h1 className="service-listing-create-edit__title">{title}</h1>

					{!isCreate && (
						<p className="service-listing-create-edit__last-update">
							<span>Last Updated By</span> {formik.values.lastModifiedBy}, {formik.values.lastModifiedDate}
						</p>
					)}
				</div>

				<form className="service-listing-create-edit__form" onSubmit={formik.handleSubmit}>
					<div className="service-listing-create-edit__container">
						<div className="service-listing-create-edit__label">Details</div>

						<div className="service-listing-create-edit__row">
							{!isCreate && /* prettier-ignore */ <AppInput name="serviceId" label="Service ID" type="text" disabled value={formik.values.serviceId} error={formik.errors.serviceId} touched={formik.touched.serviceId} onChange={formik.handleChange} />}

							{/* prettier-ignore */}
							<AppSelectInput searchable={false} required disabled={restricted} type="text" name="status" label="Status" placeholder="Select..." loadOptions={getServiceStatusListing} value={formik.values.status} error={formik.errors.status} touched={formik.touched.status} onChange={formik.handleChange} />
						</div>

						<div className="service-listing-create-edit__row">
							{/* prettier-ignore */}
							<AppSelectInput searchable={false} required disabled={restricted} type="text" name="serviceType" label="Service Type" placeholder="Select..." loadOptions={getServiceTypeListing} value={formik.values.serviceType} error={formik.errors.serviceType} touched={formik.touched.serviceType} onChange={formik.handleChange} />
						</div>

						<div className="service-listing-create-edit__row">
							{/* prettier-ignore */}
							<AppInput disabled={restricted} name="serviceAction" label="Service Action" placeholder="Enter Service Action" type="text" required value={formik.values.serviceAction} error={formik.errors.serviceAction} touched={formik.touched.serviceAction} onChange={formik.handleChange} />

							{/* prettier-ignore */}
							<AppSelectInput searchable pagination required disabled={restricted} type="text" name="input" label="Input" placeholder="Select..." loadOptions={getServiceInputListing} value={formik.values.input} error={formik.errors.input} touched={formik.touched.input} onChange={formik.handleChange} />
						</div>

						<div className="service-listing-create-edit__row service-listing-create-edit__row--divider">
							{/* prettier-ignore */}
							<AppInput required disabled={restricted} name="remarks" label="Remarks" placeholder="Enter Remarks" type="textarea" multiline maxLength={255} value={formik.values.remarks} touched={formik.touched.remarks} error={formik.errors.remarks} onChange={formik.handleChange} />
						</div>

						<SubtasksTable values={formik.values} onHandleUpdateSubtasks={onHandleUpdateSubtasks} onHandleGetDetails={onHandleGetDetails} />
					</div>

					<div className="service-listing-create-edit__button-container">
						<AppButton outline type="button" label="Cancel" onClick={onHandleCancel} />

						<AppButton type="submit" disabled={formik.isSubmitting || restricted} label={submitLabel} />
					</div>
				</form>
			</div>
		</div>
	);
};

export default memo(PageServiceListingCreateEdit);
